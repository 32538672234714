export default {
  computed: {
    ssoIdData() {
      if (window.Laravel.user !== null) {
        return { ssoId: this.$laravel.user.a1_connect_user_id };
      }

      return null;
    },
  },

  methods: {
    dataLayerPush(event, data) {
      const ssoIdData = this.ssoIdData !== null ? { ssoId: this.ssoIdData.ssoId } : {};
      window.dataLayer.push({
        event,
        ...data,
        ...ssoIdData,
      });
    },

    dataLayerPushWithData(event, data) {
      this.dataLayerPush(event, data);
    },

    dataLayerPushWithTextButton(event, text) {
      this.dataLayerPush(event, { texte_bouton: { text } });
    },

    dataLayerPushMyStepsTextButton(event, step, text) {
      this.dataLayerPush(event, { etape: { step }, texte_bouton: { text } });
    },

    dataLayerPushWithUrl(event, url) {
      this.dataLayerPush(event, { url: { url } });
    },

    dataLayerPushWithRessource(event, ressource) {
      this.dataLayerPush(event, { nom_ressource: { ressource } });
    },

    dataLayerPushRessourceFilters(event, theme, valeurFiltre) {
      this.dataLayerPush(event, { theme: { theme }, valeur_filtre: { valeurFiltre } });
    },

    dataLayerPushFilters(event, valeurFiltre) {
      this.dataLayerPush(event, { valeur_filtre: { valeurFiltre } });
    },

    dataLayerPushJobs(event, titreOffre) {
      this.dataLayerPush(event, { titre_offre: { titreOffre } });
    },

    dataLayerPushSkillsName(event, nomSkill) {
      this.dataLayerPush(event, { nom_skill: { nomSkill } });
    },

    dataLayerPushCode(event, code) {
      this.dataLayerPush(event, { code: { code } });
    },

    dataLayerPushChatCompletion(event, completion, categorie) {
      this.dataLayerPush(event, { completion: { completion }, categorie: { categorie } });
    },

    dataLayerPushMission(event, missionTitle) {
      this.dataLayerPush(event, { titre_mission: { missionTitle } });
    },

    eventTrackGtaData(pageTitle, pageUrl) {
      axios.get('/api/gta/getUserData')
        .then((response) => {
          const user = response.data.userData;
          window.dataLayer.push({
            event: 'pageview',
            user,
            pageUrl,
            pageTitle,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
