<template>
	<div class="chat-form-wrapper">
		<div
			class="chat-form"
		>
			<form @submit.prevent="">
				<div
					v-if="dropdownVisible && results.length > 0"
					class="search-dropdown"
				>
					<ul class="search-results-list">
						<li
							v-for="(result, index) in results"
							:key="'result' + index"
							class="btn btn-chatbot btn-secondary-chatbot"
							@click="(e) => selectValue(result.name, e)"
						>
							{{ result.name }}
						</li>
					</ul>
				</div>
				<div class="input-holder">
					<label
						for="text"
						class="sr-only"
					>
						Écris ta réponse
					</label>
					<input
						v-model="search"
						type="text"
						:placeholder="placeholder"
						class="form-control chatbot-textarea"
						@input="searchData"
						@focus="showDropdown"
						@blur="hideDropdown"
					>
				</div>
			</form>
		</div>
		<div class="bottom-block-wrapper d-flex justify-content-center mb-2">
			<div
				v-if="widgetProps.none"
				class="bottom-block d-flex justify-content-center mb-2"
			>
				<button
					class="btn mr-2 btn-chatbot btn-secondary-chatbot"
					type="submit"
					style="float: left"
					@click="submitEmptyValue"
				>
					{{ widgetProps.none }}
				</button>
			</div>
			<div class="text-center">
				<button
					type="submit"
					:disabled="!enabledButton"
					class="btn btn-primary btn-chatbot"
					@click="submitEnterValue"
				>
					Valider
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  props: {
    widgetProps: {
      type: Object,
      required: true,
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      search: '',
      dropdownVisible: false,
      query: '',
      results: [],
    };
  },

  computed: {
    enabledButton() {
      return this.results.filter((item) => item.name.toLowerCase() === this.search.toLowerCase()).length === 0 && this.search !== '';
    },
  },

  watch: {
    search() {
      if (this.search === '') {
        this.$emit('clear');
      }
    },
  },

  methods: {
    async searchData() {
      await axios.get(`/api/tenants?query=${this.search}`)
        .then((response) => {
          this.results = response.data;
          this.$emit('input', this.search);
        })
        .catch((error) => console.log(error));
    },

    showDropdown() {
      this.dropdownVisible = true;
    },

    selectValue(value, e) {
      this.search = value;
      this.$emit('input', value);
      this.$emit('click', e);
    },

    submitEnterValue(e) {
      this.$emit('input', this.search);
      this.$emit('click', e);
    },

    submitEmptyValue(e) {
      this.$emit('empty-value', e);
    },

    hideDropdown() {
      setTimeout(() => {
        this.dropdownVisible = false;
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-results-list {
    padding: 0;
    display: flex;
    align-items: center;
	flex-wrap: wrap;
    gap: 20px;

	.btn {
		white-space: nowrap;
	}
 }
</style>
