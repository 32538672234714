export default {
  formatDate(unFormatDate) {
    if (!unFormatDate) {
      return null;
    }
    const date = new Date(unFormatDate);
    return (
      `${(`0${date.getDate()}`).slice(-2)
      }/${(`0${date.getMonth() + 1}`).slice(-2)
      }/${date.getFullYear()}`
    );
  },
};
