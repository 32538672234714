import { apolloClient } from '_graphql';
import userIsConnected from '_graphql/userIsConnected.gql';

export default async function guest({ next, router }) {
  const { data } = await apolloClient.query({
    query: userIsConnected,
    fetchPolicy: 'network-only',
  });
  if (data.userIsConnected) {
    return router.push({ name: 'mySteps', params: { activeTab: 'me-preparer' } });
  }
  return next();
}
