<template>
	<footer id="footer">
		<div class="bg-dark text-white">
			<div class="container">
				<div class="row footer-holder">
					<div class="col-md-4 col-lg-5">
						<ul class="footer-logos list-unstyled">
							<li>
								<router-link
									:to="{ name: 'index' }"
									exact
								>
									<img
										class="lazy"
										src="/images/logo-header.svg"
										data-src="/images/logo-header.svg"
										alt="Jobready"
										width="213"
										height="35"
									>
								</router-link>
							</li>
						</ul>
						<p class="j_paragraph_18">
							Jobready est un programme de reconnaissance des compétences aidant à l’insertion sociale et
							professionnelle
							développé par l’association Article 1.
						</p>
						<p class="j_paragraph_18">
							ALLER PLUS LOIN EN CERTIFIANT UNE FAMILLE DE SOFT SKILLS <a
								target="_blank"
								rel="noopener noreferrer"
								href="https://article-1.eu/comprendre-la-certification-pratiquer-un-leadership-positif/"
							>
								<strong>LÀ</strong>.
							</a>
						</p>
					</div>
					<nav class="col-md-8 col-lg-7 footer-nav">
						<div class="row">
							<div class="col-sm-4 col-lg-5">
								<ul class="list-unstyled">
									<li>
										<router-link
											:to="{ name: 'comment_ca_marche' }"
											exact
										>
											Comment ça marche ?
										</router-link>
									</li>
									<li>
										<router-link
											:to="{ name: 'soft_skills' }"
											exact
										>
											Soft Skills
										</router-link>
									</li>
									<li>
										<router-link
											:to="{ name: 'notre_organisation' }"
											exact
										>
											Notre organisation
										</router-link>
									</li>
									<li v-if="!authUser">
										<router-link
											:to="{ name: 'devenir_partenaire' }"
											exact
										>
											Devenir partenaire
										</router-link>
									</li>
									<li v-if="!authUser">
										<a
											class="btn btn-light rounded-pill"
											@click="() => $bvModal.show('subscribe-to-newsletter')"
										>
											Recevoir notre newsletter
										</a>
									</li>
								</ul>
							</div>
							<div class="col-sm-4 col-lg-4">
								<ul class="list-unstyled">
									<li>
										<a
											href="/documents/soft-skills-référentiel.pdf"
											target="_blank"
											rel="noopener noreferrer"
										>
											Notre référentiel
										</a>
									</li>

									<li>
										<router-link
											:to="{ name: 'manifeste' }"
											exact
										>
											Notre manifeste
										</router-link>
									</li>
									<li>
										<router-link
											:to="{ name: 'contact' }"
											exact
										>
											Nous contacter
										</router-link>
									</li>
								</ul>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>
		<FooterMiddle />
		<FooterBottom />
	</footer>
</template>

<script>
import FooterMiddle from '_components/layout/footerMiddle.vue';
import FooterBottom from '_components/layout/footerBottom.vue';

export default {
  name: 'FooterLogout',
  components: {
    FooterMiddle,
    FooterBottom,
  },
  data() {
    return {
      authUser: this.$laravel.user,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '_sass/_global.scss';
@import '_sass/vendors/_include-media.scss';
</style>
