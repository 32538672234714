<template>
	<b-modal
		id="resetPwd-modal"
		header-class="modal-header text-dark"
		:hide-footer="true"
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		modal-class="modal fade full-screen-modal"
	>
		<template #modal-header>
			<router-link
				type="button"
				class="modal-btn-close"
				:to="{ name: 'index' }"
				exact
			/>
		</template>
		<template #default>
			<div class="reset-pwd-content">
				<div class="password-reset-form__container container">
					<div>
						<h1 class="j_heading-1 j_font-color-base">
							ENTRE TON EMAIL POUR
							REINITIALISER TON MOT DE PASSE
						</h1>
						<form
							action=""
							method="post"
						>
							<j-form-input
								v-model="email"
								class="resetpwd-input mt-4 mt-md-5 mb-4 mb-md-3"
								field-name="email"
								label="Email"
								field-type="email"
								:success-message="resetPwdMessageSucess"
								:errors="errors"
							/>
							<div class="text-center">
								<button
									:disabled="loading || !emailIsValid"
									class="btn btn-primary font-weight-bold"
									type="button"
									@click="submitForm"
								>
									Envoyer
								</button>
							</div>
						</form>
					</div>
				</div>
				<div class="george__img">
					<img
						src="/images/georges-resetpwd.svg"
						alt="Image de Georges embarassé"
					>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import jFormInput from '../layout/jFormInput.vue';

export default {
  components: { jFormInput },
  data() {
    return {
      loading: false,
      email: '',
      errors: {
        email: null,
      },
      resetPwdMessageSucess: null,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },

  computed: {
    emailIsValid() {
      return this.checkEmailValid();
    },
  },

  watch: {
    $route: {
      handler() {
        this.modalShowing();
      },
    },
  },

  mounted() {
    this.modalShowing();
  },

  methods: {
    checkEmailValid() {
      return this.email.length === 0 ? '' : this.reg.test(this.email);
    },

    modalShowing() {
      if (this.$route.name === 'resetPwd') {
        this.$bvModal.show('resetPwd-modal');
      } else {
        this.$bvModal.hide('resetPwd-modal');
      }
    },

    submitForm(e) {
      e.preventDefault();

      this.loading = true;

      this.errors = {
        email: null,
      };

      axios
        .post('/api/resetPwd', {
          email: this.email,
        })
        .then((response) => {
          this.loading = false;
          if (response.data.state === 'ok') {
            this.passwordReset = true;
            this.resetPwdMessageSucess =									'Si votre compte existe, un email vient d’être envoyé';
            this.email = '';
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            console.log(error);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "_sass/_global.scss";

#resetPwd-modal___BV_modal_outer_ {
	z-index: 100 !important;
}

.success__message {
	color: $green;
}

.reset-pwd-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	form {
		width: 80%;
	}

	.george__img {
		display: flex;
		opacity: 0.6;
		justify-content: center;
	}

	@media (min-width: 1000px) {
		display: grid;
		grid-template-columns: 20% 80%;
		align-items: center;
		height: 100%;
		.george__img {
			display: unset;
			grid-area: 1;
			align-self: center;

			img {
				width: 100%;
			}
		}
		.password-reset-form__container {
			display: grid;
			grid-template-columns: 60% 40%;
			align-items: center;
			height: 100%;
			margin-left: 9rem;

			form {
				width: 75%;
			}
		}
	}
}
</style>
