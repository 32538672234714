<template>
	<!-- tags list -->
	<div class="tags-list-wrapper">
		<ul v-if="options.redirection && redirection !== '/' && redirection !== '/connexion'" class="tags-list">
			<li v-if="options.redirection">
				<a @click="response(options.redirection)">
					<span class="btn btn-chatbot btn-secondary-chatbot">
						{{ options.redirection }}
					</span>
				</a>
			</li>
		</ul>
		<ul v-else class="tags-list">
			<li
				v-for="(item, itemIndex) in options.values"
				:key="itemIndex"
			>
				<a @click="response(item)">
					<span
						v-if="item === 1"
						class="btn btn-chatbot btn-secondary-chatbot"
					>
						(Presque) Jamais
					</span>
					<span
						v-else-if="item === 2"
						class="btn btn-chatbot btn-secondary-chatbot"
					>
						Parfois
					</span>
					<span
						v-else-if="item === 3"
						class="btn btn-chatbot btn-secondary-chatbot"
					>
						Souvent
					</span>
					<span
						v-else-if="item === 4"
						class="btn btn-chatbot btn-secondary-chatbot"
					>
						(Presque) Toujours
					</span>

					<span
						v-else-if="item === true"
						class="btn btn-chatbot btn-secondary-chatbot"
					>
						Oui
					</span>
					<span
						v-else-if="item === false"
						class="btn btn-chatbot btn-secondary-chatbot"
					>
						Non
					</span>
					<span
						v-else-if="item === 5"
						class="btn btn-chatbot btn-secondary-chatbot"
					>
						Je ne peux pas répondre
					</span>
					<span v-else>
						<span
							v-if="!isIframe"
							class="btn btn-chatbot btn-secondary-chatbot"
						>
							{{ item }}
						</span>
						<!-- hide button to view profile on chatbot -->
						<span v-else>
							<span
								v-if="item != 'Voir mon profil'"
								class="btn btn-chatbot btn-secondary-chatbot"
							>
								{{ item }}
							</span>
						</span>
					</span>
				</a>
			</li>
			<li v-if="options.none">
				<a @click="response('')">
					<span class="btn btn-chatbot btn-secondary-chatbot">
						{{ options.none }}
					</span>
				</a>
			</li>
			<li v-if="options.other">
				<a @click="pushOtherQuestion">
					<span class="btn btn-chatbot btn-secondary-chatbot">
						{{ options.other }}
					</span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),

    },
    questionKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      redirection: this.$route.query.redirect,
      isIframe: window.Laravel.isIframe,
    };
  },

  methods: {
    response(value) {
      // create the response data that will be sent to the parent component with the key of the question and the responses
      const data = {
        key: this.questionKey,
        value,
      };

      // Get the text message that will be shown in the chatbot as a user message
      let messageContent = '';

      // if items are true false show Oui/Non to the user
      switch (value) {
        case true:
          messageContent = 'Oui';
          break;
        case false:
          messageContent = 'Non';
          break;
        case 1:
          messageContent = '(Presque) Jamais';
          break;
        case 2:
          messageContent = 'Parfois';
          break;
        case 3:
          messageContent = 'Souvent';
          break;
        case 4:
          messageContent = '(Presque) Toujours';
          break;
        case 5:
          messageContent = 'Je ne peux pas répondre';
          break;
        case '':
          messageContent = this.options.none;
          break;
        default:
          messageContent = value;
      }
      // Set the text message as object
      const message = {
        label: messageContent,
        user: true,
        key: this.questionKey,
      };
      // Emit event to call 'pushMessage' function in parent component with the new message created
      this.$emit('pushMessage', message);
      // Emit Event to call 'disableInputs' function in parent component to disable the widget
      this.$emit('disableTagList');
      // Emit event to call 'response' function in parent component and send data to it
      this.$emit('answered', data);
    },
    // If other case is checked push the label of the other question in chatbot
    pushOtherQuestion() {
      this.$emit('pushOtherQuestion');
    },
  },
};
</script>

<style scoped lang="scss">
@import "_sass/_global.scss";
.tags-list-wrapper {
	position: relative;
	display: block;
	width: auto;
	max-width: 100%;
	margin: 0 auto;

	.tags-list {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
}

</style>
