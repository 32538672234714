<template>
	<div
		ref="singleChoice"
		class="message-block user-message"
	>
		<!-- chat cta area -->
		<div class="chat-cta-block">
			<form @submit.prevent="">
				<div class="content-holder checkbox-block">
					<!-- custom checkbox -->
					<div
						v-for="(value, key) in options.values"
						:key="key"
						class="custom-checkbox custom-jr-radio custom-control check-right p-0"
						:class="selectedElement && (selectedElement === value || selectedElement.value === value) ? 'selected-radio' : '' "
						@click="activateValidationBtn"
					>
						<input
							:id="key"
							type="radio"
							name="singleElement"
							class="custom-control-input"
							:value="value"
							@change="setSelectedItem(key, value)"
						>
						<label
							class="custom-control-label p-0"
							:for="key"
						>
							<!-- skill block sm -->
							<span
								class="skill-block sm"
							>
								<span class="inner-block">
									<strong class="title">
										<div v-if="value.value">
											{{ value.value }}
										</div>
										<div v-else>
											{{ value }}
										</div>
									</strong>
								</span>
							</span>
						</label>
					</div>
					<!-- other checkbox -->
					<div
						v-if="options.other"
						class="custom-checkbox custom-jr-radio custom-control check-right p-0"
						:class="{ 'selected-radio': selectedElement === -1 }"
						@click="activateValidationBtn"
					>
						<input
							id="-1"
							type="radio"
							name="singleElement"
							class="custom-control-input"
							:value="-1"
							@change="selectedElement = -1"
						>
						<label
							class="custom-control-label p-0"
							for="-1"
						>
							<!-- skill block sm -->
							<span
								class="skill-block sm"
							>
								<span class="inner-block">
									<strong class="title">
										{{ options.other }}
									</strong>
								</span>
							</span>
						</label>
					</div>
					<!-- none checkbox -->
					<div
						v-if="options.none"
						class="custom-checkbox custom-jr-radio custom-control check-right p-0"
						:class="{ 'selected-radio': selectedElement === '' }"
						@click="activateValidationBtn"
					>
						<input
							id="none"
							type="radio"
							:value="''"
							name="singleElement"
							class="custom-control-input"
							@change="selectedElement = ''"
						>
						<label
							class="custom-control-label p-0"
							for="none"
						>
							<!-- skill block sm -->
							<span
								class="skill-block sm"
							>
								<span class="inner-block">
									<strong class="title">
										{{ options.none }}
									</strong>
								</span>
							</span>
						</label>
					</div>
				</div>
				<div
					v-if="options.showSearchBar"
					class="input-search mt-2"
				>
					<input
						id="profession-search-bar"
						type="text"
						name="search-bar"
						class="form-control btn-search-chatbot pl-3"
						placeholder="Recherche..."
						@click.stop="scrollToSearchInput"
						@keyup="filter($event.target.value)"
					>
				</div>
				<div class="bottom-block mt-4 mb-2">
					<button
						class="btn btn-primary btn-chatbot btn-primary"
						type="button"
						:disabled="!validate"
						@click="response"
					>
						Valider
					</button>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    questionKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedElement: null,
      validate: false,
      showSeeMore: true,
    };
  },

  mounted() {
    window.addEventListener('resize', this.scrollToSearchInput);
  },

  unmounted() {
    window.removeEventListener('resize', this.scrollToSearchInput);
  },

  methods: {
    response(e) {
      e.preventDefault();
      // If other case is checked push the label of the other question in chatbot
      if (this.selectedElement === -1) {
        if (this.questionKey !== 'workshopRegion') {
          this.$emit('pushOtherQuestion');
          return;
        }
        this.selectedElement = 'Autre';
      }
      delete this.selectedElement.key;
      // Get the key of the current question and the element selected By the user
      const data = {
        key: this.questionKey,
        value: this.selectedElement,
      };

      // Get the text message that will be shown in the chatbot as a user message if not null
      let messageContent;
      if (this.selectedElement.value) {
        // If selectedElement is an array and has a value attribute show the value in the chatbot
        messageContent = this.selectedElement.value;
      } else if (this.selectedElement) {
        // If selectedElement is a string show it in the chatbot
        messageContent = this.selectedElement;
      } else {
        // If selectedElement is empty show the none label in the chatbot
        messageContent = this.options.none;
      }

      // Set the text message as object
      const message = {
        label: messageContent,
        user: true,
        key: this.questionKey,
      };

      // Emit event to call 'pushMessage' function in parent component with the new message created
      this.$emit('pushMessage', message);

      // Emit Event to call 'disableInputs' function in parent component to disable the widget
      this.$emit('disableSingleChoice');
      // Emit event to call 'response' function in parent component and send data to it
      this.$emit('answered', data);
    },
    activateValidationBtn() {
      this.scrollToSearchInput();
      this.validate = true;
    },
    filter(searchContent) {
      const filterEndpoints = {
        profession: '/api/chatbot/filter-profession',
        rolesVolunteers: '/api/chatbot/filter-roles-volunteers',
        sport: '/api/chatbot/filter-sports',
        hobby: '/api/chatbot/filter-hobbies',
        tasks: '/api/chatbot/filter-tasks',
        hobbiesAndSports: '/api/chatbot/filter-hobbies-sports',
        highSchoolDepartment: '/api/chatbot/filter-high-school-department',
        highSchool: 'api/chatbot/filter-high-school',
      };
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const data = {
          searchContent,
        };
        axios.post(filterEndpoints[this.questionKey], data).then((response) => {
          this.options.values = response.data.values.values;
        }).catch((error) => {
          console.log(error);
        });
      }, 200);
    },
    setSelectedItem(key, item) {
      console.log(item);
      this.selectedElement = item;
    },
    seeMore() {
      let pathName = '';
      if (this.questionKey === 'program') {
        pathName = 'all-programs';
      } else if (this.questionKey === 'roleProgram') {
        pathName = 'all-role-programs';
      }
      axios.post(`/api/chatbot/${pathName}`).then((response) => {
        this.options.values = response.data.values;
        this.showSeeMore = false;
      }).catch(() => {
        const message = {
          label: 'Une erreur est survenue, rafraîchis la page !',
        };
        this.messages.push(message);
      });
    },
    scrollToSearchInput() {
      const element = this.$refs.singleChoice;
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
  },
};
</script>
<style scoped lang="scss">
@import "_sass/_global.scss";
// SingleChoice widget searchbar
.chat-cta-block {
	text-align: center;

	.content-holder {
		margin-top: 12px;
		box-shadow: $box-shadow;
		border-radius: 10px;
		height: 180px;
	}

	.checkbox-block .custom-control {
		border-top: none;
	}

	.selected-radio {
		background: rgba(255, 223, 74, 0.3) !important;
	}
}

.custom-control-label {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
