/* eslint-disable */
import moment from 'moment';

export default {
  methods: {
    getDate(experience) {
      const startDate = this.formatDate(experience.scenario.startDate);
      if (!startDate) {
        return '';
      }

      if (experience.scenario.endDate) {
        const endDate = this.formatDate(experience.scenario.endDate);
        if (!endDate) {
          return '';
        }
        return (`${startDate} - ${endDate}`);
      }
      return (`Depuis ${this.formatDate(experience.scenario.startDate)}`);
    },

    getExperienceDuration(experience) {
      const formatDate = (dateValue) => (dateValue.length === 6
        ? new Date([dateValue.slice(0, 5), '0', dateValue.slice(5)].join(''))
        : new Date(dateValue));

      // Safari and IE only recognize the format yyyy-mm and not yyyy-mm
      const startDate = formatDate(experience.scenario.startDate);
      // Safari and IE only recognize the format yyyy-mm and not yyyy-mm
      const endDate = experience.scenario.endDate ? formatDate(experience.scenario.endDate) : new Date();

      // endDate is actually at the first of the month --> add one month to make it at the end of the month
      const duration = moment((moment(endDate).add(1, 'M'))).diff(moment(startDate), 'months', true);
      const yearDuration = Math.floor(duration / 12);
      const monthDuration = Math.floor(duration % 12);

      // Fomat the displayed duration
      if (monthDuration === 0) {
        return (yearDuration.toString() + ((yearDuration === 1) ? ' an' : ' ans'));
      } if (yearDuration === 0) {
        return (`${monthDuration.toString()} mois`);
      }
      return (`${yearDuration.toString() + ((yearDuration === 1) ? ' an ' : ' ans ') + monthDuration.toString()} mois`);
    },

    // Compute and format the displayed duration
    getDuration(experience) {
      // If the experience is over
      if (experience.scenario.endDate) {
        const startDate = new Date(experience.scenario.startDate);
        const endDate = new Date(experience.scenario.endDate);

        // endDate is actually at the first of the month --> add one month to make it at the end of the month
        const duration = moment((moment(endDate).add(1, 'M'))).diff(moment(startDate), 'months', true);
        const yearDuration = Math.floor(duration / 12);
        const monthDuration = Math.floor(duration % 12);

        if ((!duration) || (!yearDuration) || (!monthDuration)) {
          return '';
        }

        // Fomat the displayed duration
        if (monthDuration === 0) {
          return (` * ${yearDuration.toString()}${(yearDuration === 1) ? ' an' : ' ans'}`);
        } if (yearDuration === 0) {
          return (` * ${monthDuration.toString()} mois`);
        }
        return (` * ${yearDuration.toString()}${(yearDuration === 1) ? ' an ' : ' ans '}${monthDuration.toString()} mois`);
      }

      // If the experience is not finished
      return '';
    },

    formatDate(date) {
      const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre',
      ];
      if (!date) {
        return null;
      }

      // Safari and IE only recognize the format yyyy-mm and not yyyy-mm
      const newDate = date.length === 6 ? new Date([date.slice(0, 5), '0', date.slice(5)].join('')) : new Date(date);
      return (`${monthNames[newDate.getMonth()]} ${newDate.getFullYear()}`);
    },

    checkDates(date) {
      if (!date) {
        return true;
      }
      const today = new Date();
      const addDate = new Date(date);
      const firstDate = moment(today, 'YYYY-MM-DD');
      const secondDate = moment(addDate, 'YYYY-MM-DD');
      return firstDate.diff(secondDate, 'days') >= 0;
    },

    getDaysPassed(date) {
      const today = new Date();
      const addDate = new Date(date);
      const firstDate = moment(today, 'YYYY-MM-DD');
      const secondDate = moment(addDate, 'YYYY-MM-DD').subtract(14, 'days');
      return firstDate.diff(secondDate, 'days');
    },

    getDiffDays(date) {
      const today = new Date();
      const addDate = new Date(date);
      const firstDate = moment(today, 'YYYY-MM-DD');
      const secondDate = moment(addDate, 'YYYY-MM-DD');
      return secondDate.diff(firstDate, 'days');
    },
  },
};
