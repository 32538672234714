<template>
	<div class="footer-middle">
		<div class="container">
			<div class="footer-middle__content">
				<div class="creator-section separator">
					<p class="j_heading-2 j_font-color-base">
						CRÉÉ PAR :
					</p>
					<img
						class="project-logo"
						src="/images/logos/a1-logo.svg"
						alt=""
						srcset=""
					>
					<p class="j_paragraph_18">
						Article 1 œuvre pour une société dans laquelle l’orientation, la réussite d’études et la trajectoire
						professionnelle ne
						dépendent plus des origines, pour une société où la réussite passe par le lien social et l’engagement citoyen.
					</p>
					<a
						href="https://article-1.eu/"
						class="btn-more"
						target="_blank"
						rel="noopener noreferrer"
					>
						<i class="icon-arrow-r" />EN SAVOIR PLUS
					</a>
				</div>
				<div class="projects-section">
					<p class="j_heading-2 j_font-color-base">
						LES AUTRES PROJETS D’ARTICLE 1 :
					</p>
					<div class="projets">
						<div class="projet">
							<img
								class="project-logo"
								src="/images/logos/inspire-logo.svg"
								alt=""
								srcset=""
							>
							<p class="j_paragraph_18">
								INSPIRE un programme d’aide à l’orientation pour les lycéen.nes.
							</p>
							<a
								href="https://inspire-orientation.org/"
								class="btn-more"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i class="icon-arrow-r" />TROUVE TA VOIE
							</a>
						</div>
						<div class="projet">
							<img
								class="project-logo"
								src="/images/logos/demain-logo.svg"
								alt=""
								srcset=""
							>
							<p class="j_paragraph_18">
								DEMA1N.org un programme d’aide à la réussite des études destiné aux étudiant.es.
							</p>
							<a
								href="https://www.dema1n.org/"
								class="btn-more"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i class="icon-arrow-r" />TROUVE UN MENTOR
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'FooterMiddle',
};

</script>

<style scoped lang="scss">
@import '_sass/_global.scss';
@import '_sass/vendors/_include-media.scss';
.footer-middle {
    background: linear-gradient(0deg, rgba(255, 223, 0, 0.2), rgba(255, 223, 0, 0.2)), #FFFFFF;
    min-height: 200px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
}
.footer-middle__content {
    display: grid;
    grid-template-columns: 5fr 7fr;
    gap: 130px;
}
.separator {
    position: relative;

    &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 1px;
            background: $gray-900;
            right: -60px;
            top: 50%;
            z-index: 1000;
            transform: translateY(-50%);

            @include media('<desktop') {
                height: 1px;
                top: unset;
                bottom: -32px;
                width: 100%;
                right: 0;
            }
        }
}
.btn-more, .btn-more i {
    color: $gray-900 !important;
}
.projets {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}
.projet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    img { height: 100px;}
}

@include media('<desktop') {
    .projets {
            flex-direction: column;
            align-items: flex-start;
        }
                .footer-middle__content {
                    grid-template-columns: 1fr;
                    gap: 65px;
                }
}
</style>
