<template>
	<div class="input__container">
		<input
			v-model="search"
			type="text"
			:placeholder="placeholder"
			:class="classname"
			@input="searchAddress"
			@focus="showDropdown"
			@blur="hideDropdown"
		>
		<div
			v-if="dropdownVisible && results.length > 0"
			class="autocomplete-dropdown"
		>
			<ul class="autocomplete-search-results-list">
				<li
					v-for="(result, index) in results"
					:key="'result' + index"
					class="autocomplete-search-result"
					@click="selectAddress(result.properties)"
				>
					{{ result.properties.label }} <small>{{ result.properties.context }}</small>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    classname: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      search: '',
      dropdownVisible: false,
      query: '',
      results: [],
    };
  },

  watch: {
    search() {
      if (this.search === '') {
        this.$emit('clear');
      }
    },
  },

  methods: {
    async searchAddress() {
      await axios.get(`api/chatbot/address-search/${this.search}`)
        .then((response) => {
          this.results = response.data.features;
        })
        .catch((error) => console.log(error));
    },

    selectAddress(address) {
      this.search = address.label;
      this.$emit('setAddress', address);
    },

    showDropdown() {
      this.dropdownVisible = true;
    },

    hideDropdown() {
      setTimeout(() => {
        this.dropdownVisible = false;
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.autocomplete {
    position: relative;
    width: 20rem;
}

.autocomplete-dropdown {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    z-index: 999999;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    background-color: #fff;
}

.autocomplete-search-results-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.autocomplete-search-result {
    padding: 0.75rem 1rem;
    cursor: pointer;
    border-top: 1px solid #e6e6e6;
    font-size: 14px;

    small {
        font-size: 11px;
        color: #999;
    }
}

.autocomplete-search-result:hover,
.autocomplete-search-result:focus,
.autocomplete-search-result:active {
    background-color: #f9fafc;
}
</style>
