/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { apolloClient } from '../graphql';
import dataCountQuery from '../graphql/dataCount.gql';

const state = {
  showPopup: false,
  jobreadynessProgress: 0,
  badgesCount: 0,
  experienceCount: 0,
  citizenExperiencesCount: 0,
  completedAction: 'step-1-action-1',
  stepProData: null,
};

const mutations = {
  showJobreadynessModal(state, payload) {
    state.showPopup = true;
    state.completedAction = payload;
  },
  hideJobreadynessModal(state) {
    state.showPopup = false;
    state.completedAction = null;
  },
  setJobreadynessData(state, payload) {
    state.jobreadynessProgress = payload.jobreadynessProgress;
    state.badgesCount = payload.userBadgesCount;
    state.experienceCount = payload.userExperiencesCount;
    state.citizenExperiencesCount = payload.citizenExperiencesCount;
  },
  setStepProData(state, payload) {
    state.stepProData = payload;
  },
};

const actions = {
  async fetchJobreadynessData({ commit }) {
    try {
      const { data } = await apolloClient.query({
        query: dataCountQuery,
        fetchPolicy: 'network-only',
      });
      commit('setJobreadynessData', data);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchStepProData({ commit }) {
    try {
      const response = await axios.get('/api/jobreadyness-data');
      if (response.status === 200) {
        commit('setStepProData', response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  setJobreadynessAction({ state, commit, dispatch }, payload) {
    dispatch('fetchJobreadynessData').then(() => {
      if (state.experienceCount === 3) {
        commit('showJobreadynessModal', 'step-1-action-1');
      } else {
        const questionIndice = payload.findIndex((elm) => elm.key === 'context');
        const userResponse = payload[questionIndice + 1];
        if (
          (state.experienceCount === 1)
                    && userResponse && userResponse.label
                    && userResponse.label.includes('Engagement')) {
          commit('showJobreadynessModal', 'step-1-action-2');
        }
      }
    });
  },
};

const getters = {
  getExperiencesCount: (state) => state.experienceCount,
  getCitizenExperiencesCount: (state) => state.citizenExperiencesCount,
  getBadgesCount: (state) => state.badgesCount,
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
