<template>
	<!-- main container of all the page elements -->
	<div
		id="wrapper"
		class="home-page error-page"
	>
		<header-progress />

		<!-- contain main informative part of the site -->
		<main
			id="main"
			class="main"
		>
			<div class="container">
				<!-- user profile section -->
				<div class="profile-section">
					<!-- user profile details -->
					<div class="profile-details">
						<div class="row">
							<div class="col-12 col-md-12">
								<!-- user skills -->
								<div class="skills-block">
									<div class="chart-block">
										<img
											src="./../../../images/222.png"
											class="image-center"
											alt="503"
										>
										<h4 class="text-center">
											<b>Aïe, erreur 503 server error </b>
										</h4>
										<h4 class="text-center">
											<b>On dirait que notre serveur a un problème, je me mets au boulot pour réparer ça</b>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
		<footer-logout />
	</div>
</template>
<script>
import FooterLogout from '_components/logout/footer.vue';
import MobileNav from '../mixins/jqueryInitMobileNav';

export default {

  name: 'SoftSkillsFamily',

  components: {
    FooterLogout,
  },
  mixins: [MobileNav],
};
</script>
