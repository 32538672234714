<template>
	<div class="bg-dark text-white footer-bottom">
		<div class="container">
			<div class="footer-bottom__content">
				<div class="d-flex align-items-center gap-2">
					<div class="copyright j_paragraph_14">
						© {{ year }} Article 1
					</div>
					<a
						href="https://www.instagram.com/article_un/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<font-awesome-icon :icon="['fab', 'instagram']" />
					</a>
					<a
						href="https://www.linkedin.com/search/results/content/?keywords=jobready&sid=Cx!&update=urn%3Ali%3Afs_updateV2%3A(urn%3Ali%3Aactivity%3A6999471725973250048%2CBLENDED_SEARCH_FEED%2CEMPTY%2CDEFAULT%2Cfalse)"
						target="_blank"
						rel="noopener noreferrer"
					>
						<font-awesome-icon :icon="['fab', 'linkedin']" />
					</a>
				</div>
				<div class="footer-bottom__decor">
					<img
						src="/images/decor-logo-footer.svg"
						alt=""
						srcset=""
					>
				</div>
				<ol class="breadcrumb">
					<li class="breadcrumb-item j_paragraph_14">
						<a href="https://connect.article-1.eu/legals/politique-confidentialite" target="_blank" rel="noopener noreferrer">Politique de confidentialité</a>
					</li>
					<li class="breadcrumb-item j_paragraph_14">
						<a href="https://connect.article-1.eu/legals/conditions-generales" target="_blank" rel="noopener noreferrer">CGU</a>
					</li>
					<li
						class="breadcrumb-item j_paragraph_14"
						aria-current="page"
					>
						<router-link
							:to="{ name: 'mentions_legales' }"
							target="_blank"
							exact
						>
							Mentions légales
						</router-link>
					</li>
				</ol>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'FooterBottom',
  data() {
    return {
      authUser: this.$laravel.user,
      year: new Date().getFullYear(),
    };
  },
};

</script>

<style lang="scss" scoped>
@import '_sass/_global.scss';
@import '_sass/vendors/_include-media.scss';
.footer-bottom {
	padding-top: 20px;
	padding-bottom: 20px;
	height: 215px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer-bottom__decor {
	height: 85px;
	width: 85px;

	@include media('>=desktop') {
		margin-right: -10%;
	}
}
.footer-bottom__content {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media('<tablet') {
		flex-direction: column-reverse;
		justify-content: center;
		gap: 10px;
	}
}

.breadcrumb-item::before{
  color: $gray-600;
}
</style>
