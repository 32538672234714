<template>
	<!-- header of the page  -->
	<!-- add to <body class "logged" to change user status> -->
	<div v-if="!landingPage">
		<header
			v-if="!user"
			id="header"
			class="header bg-white"
		>
			<top-panel />
			<subheader-softskillsfamily
				v-if="$route.name === 'family_name'"
				:data-type="currentFamilySkills"
				:current-family-skills="currentFamilySkills"
			/>
		</header>
		<auth-header v-else />
	</div>
	<div v-else>
		<header
			id="header"
			class="header bg-white"
		>
			<top-panel :landing-page="landingPage" />
			<subheader-softskillsfamily
				v-if="$route.name === 'family_name'"
				:data-type="currentFamilySkills"
				:current-family-skills="currentFamilySkills"
			/>
		</header>
	</div>
</template>

<script>
import SubHeaderSoftSkillsFamily from '_components/logout/subHeaderSoftSkillsFamily.vue';
import AuthHeader from './authHeader.vue';

export default {
  name: 'HeaderProgress',

  components: {
    'subheader-softskillsfamily': SubHeaderSoftSkillsFamily,
    AuthHeader,
  },

  props: {
    currentFamilySkills: {
      type: Object,
      default: () => {},
    },

    landingPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menuIcon: true,
      user: this.$laravel.user,
    };
  },
};

</script>
