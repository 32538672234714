<template>
	<div
		v-if="isVision"
		class="visions-iframe-compass"
	>
		<iframe
			v-show="iframeIsVisible"
			title="Vision Ifrmae"
			src="https://visionstrust.com/v1/iframes/experience/5fb272090ee6207b28216bab/61b8808e4cd5f999d4874ab1?nojs=true&nobutton=true"
		/>
		<img
			src="/images/visions-boussole.svg"
			class="visions-iframe-compass-img"
			alt="boussole"
			@click="() => iframeIsVisible = !iframeIsVisible"
		>
	</div>
</template>

<script>

export default {
  name: 'Vision',

  data() {
    return {
      isVision: false,
      iframeIsVisible: false,
    };
  },

  created() {
    this.isVisionUser();
  },

  methods: {
    isVisionUser() {
      axios
        .get('/api/visiontrust/vision-tokens')
        .then((response) => {
          this.isVision = response.data.isVisionUser;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.visions-iframe-compass {
	position: fixed;
	bottom: 1.25rem;
	left: 1.25rem;
	cursor: pointer;
	.visions-iframe-compass-img {
		width: 4.5rem;
		height: 4.5rem;
	}
}

iframe {
	position: absolute;
	bottom: 4.5rem;
	left: 0;
	border: none;
	width: 310px;
	height: 510px;

	@media(min-width: 576px) {
		bottom: 0;
		left: 4.5rem;
	}
}
</style>
