<template>
	<a
		href="/visions-trust-login"
		class="btn btn-visions-trust rounded-pill"
	>
		<span class="pr-1">
			<img
				src="/images/logo-visions.svg"
				class="pb-1"
				alt="visions-trust-logo"
				height="15"
				width="15"
			>
		</span>
		<span>
			{{ label }}
		</span>
	</a>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-visions-trust {
	border: 1px solid #16243d;
	color: #16243d;
	font-weight: 700;
	display: inline-block;
}
</style>
