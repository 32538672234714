<template>
	<nav class="navbar navbar-expand-lg navbar-light">
		<strong
			class="navbar-brand flex-shrink-0"
			@click="removeNavMenu"
		>
			<router-link
				:to="{ name: 'index' }"
				exact
			>
				<img
					class="lg d-none d-sm-block d-xs-none"
					src="/images/logo-header.svg"
					data-src="/images/logo-header.svg"
					alt="Jobready logo"
				>
				<img
					class="sm d-block d-sm-none"
					data-src="/images/logo-sm.svg"
					src="/images/logo-sm.svg"
					alt="Jobready logo"
				>
			</router-link>
		</strong>
		<div class="nav-holder d-lg-flex justify-content-lg-between align-items-lg-center flex-grow-1">
			<div
				id="navbarMenu"
				class="collapse navbar-collapse"
			>
				<ul
					id="nav"
					class="navbar-nav"
				>
					<li
						class="nav-item"
						:class="{ active: $route.name === 'comment_ca_marche' }"
						@click="removeNavMenu"
					>
						<router-link
							:to="{ name: 'comment_ca_marche' }"
							exact
							class="nav-link"
						>
							Comment ça marche ?
						</router-link>
					</li>
					<li
						class="nav-item"
						:class="{ active: $route.name === 'soft_skills' }"
						@click="removeNavMenu"
					>
						<router-link
							:to="{ name: 'soft_skills' }"
							exact
							class="nav-link"
						>
							Soft Skills
						</router-link>
					</li>
					<li
						class="nav-item"
						:class="{ active: $route.name === 'notre_organisation' }"
						@click="removeNavMenu"
					>
						<router-link
							:to="{ name: 'notre_organisation' }"
							exact
							class="nav-link"
						>
							Notre organisation
						</router-link>
					</li>
					<li
						class="nav-item"
						:class="{ active: $route.name === 'devenir_partenaire' }"
						@click="removeNavMenu"
					>
						<router-link
							:to="{ name: 'devenir_partenaire' }"
							exact
							class="nav-link"
						>
							Devenir partenaire
						</router-link>
					</li>
				</ul>
				<ul class="navbar-nav add-nav font-weight-normal d-lg-none">
					<li
						class="nav-item"
						@click="removeNavMenu"
					>
						<a
							class="nav-link"
							href="/documents/soft-skills-référentiel.pdf"
							target="_blank"
						>
							Notre référentiel
						</a>
					</li>
					<li
						class="nav-item"
						@click="removeNavMenu"
					>
						<router-link
							class="nav-link"
							:to="{ name: 'manifeste' }"
							exact
						>
							Notre manifeste
						</router-link>
					</li>
					<li
						class="nav-item"
						@click="removeNavMenu"
					>
						<router-link
							class="nav-link"
							:to="{ name: 'contact' }"
							exact
						>
							Nous contacter
						</router-link>
					</li>
				</ul>
			</div>
			<div v-if="!landingPage" class="btn-holder d-flex justify-content-end align-items-center flex-shrink-0">
				<div v-if="!a1ConnectIsEnabled">
					<router-link
						:to="{ name: 'login' }"
						exact
						class="btn btn-link btn-sm rounded-pill"
						@click.native="dataLayerPushUrl"
					>
						Se connecter
					</router-link>
				</div>

				<div v-if="!a1ConnectIsEnabled">
					<router-link
						:to="{ name: 'register' }"
						exact
						class="btn btn-primary btn-sm rounded-pill"
						@click.native="dataLayerPush('clicCTADemarrerInscriptionMenu')"
					>
						S’inscrire
					</router-link>
				</div>
				<div v-if="a1ConnectIsEnabled">
					<a
						class="btn btn-primary btn-sm rounded-pill"
						href="/a1-connect/auth?usePrevUrl=false"
					>S’inscrire | Se connecter</a>
				</div>
				<button
					id="navbar-toggler"
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarMenu"
					aria-controls="navbarMenu"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span />
				</button>
				<a
					class="nav-opener"
					href="#"
				>
					<span />
				</a>
			</div>
			<div v-else>
				<button
					id="navbar-toggler"
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarMenu"
					aria-controls="navbarMenu"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span />
				</button>
				<a
					class="nav-opener"
					href="#"
				>
					<span />
				</a>
			</div>
		</div>
		<subscribe-to-newsletter />
	</nav>
</template>

<script>
import subscribeToNewsletter from '_components/homepage/subscribeToNewsletter.vue';
import DataLayerPush from '_components/mixins/dataLayerPush';

export default {
  name: 'LogoutHeader',
  components: { subscribeToNewsletter },
  mixins: [DataLayerPush],

  props: {
    landingPage: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loginModalOpen: false,
    registerModalOpen: false,
  }),

  computed: {
    a1ConnectIsEnabled() {
		  return this.$laravel.a1ConnectEnabled;
    },
  },

  watch: {
    $route: {
      handler() {
        this.modalShowing();
      },
    },
  },

  mounted() {
    this.modalShowing();
  },

  methods: {
    setRegisterActiveHeader() {
      this.registerModalOpen = true;
      this.dataLayerPush('clicCTADemarrerInscriptionMenu');
    },
    setLoginActiveHeader() {
      this.loginModalOpen = true;
    },

    /**
		 * Close the menu
		 */
    removeNavMenu: () => {
      if (document.body.classList.contains('nav-active')) {
        document.body.classList.remove('nav-active');
      }
    },

    modalShowing() {
      if (this.$route.name === 'login') {
        this.$bvModal.show('login-modal');
        this.$bvModal.hide('register-modal');
        this.loginModalOpen = true;
      } else if (this.$route.name === 'register') {
        this.$bvModal.show('register-modal');
        this.$bvModal.hide('login-modal');
        this.registerModalOpen = true;
      } else {
        this.$bvModal.hide('login-modal');
        this.$bvModal.hide('register-modal');
      }
    },
    dataLayerPushUrl() {
      this.dataLayerPushWithUrl('connexion_header', window.location.href);
    },
  },
};

</script>
