/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import { createApp, configureCompat } from 'vue';
import InstantSearch from 'vue-instantsearch/vue3/es';
import BootstrapVue from 'bootstrap-vue';
import VueTextareaAutosize from 'vue-textarea-autosize';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUndo, faQuestion, faHourglassHalf, faEquals, faCheck, faTimes, faThumbsUp, faFrown, faTrash, faSignOutAlt, faHeart as fasHeart, faArrowLeft, faPaperPlane, faChevronLeft, faPen, faQuoteLeft, faArrowUpRightFromSquare, faInfoCircle, faArrowDown, faArrowRight, faArrowUpFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import {
  faQuestionCircle, faTimesCircle, faHeart as farHeart, faEye, faEyeSlash,
} from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueObserveVisibility from 'vue-observe-visibility';
import VueScrollTo from 'vue-scrollto';
import VueTippy from 'vue-tippy';
import { createMetaManager } from 'vue-meta';
import VueLazyload from 'vue-lazyload';
import vSelect from 'vue-select';
import { provideApolloClient } from '@vue/apollo-composable';
import * as Sentry from '@sentry/vue';
import { apolloProvider, apolloClient } from './graphql';
import store from './store';
import router from './router';
import filters from './functions/filters';

require('./bootstrap');

const app = createApp({});

Sentry.init({
  app,
  dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
  environment: process.env.MIX_SENTRY_VUE_ENVIRONNEMENT,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: process.env.MIX_TRACES_SAMPLE_RATE,
  replaysSessionSampleRate: process.env.MIX_REPLAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: process.env.MIX_REPLAYS_ON_ERROR_SAMPLE_RATE,
});

// https://github.com/bootstrap-vue/bootstrap-vue/issues/7128
configureCompat({
  WATCH_ARRAY: 'suppress-warning',
  RENDER_FUNCTION: 'suppress-warning',
  INSTANCE_LISTENERS: 'suppress-warning',
  COMPONENT_FUNCTIONAL: 'suppress-warning',
  OPTIONS_BEFORE_DESTROY: 'suppress-warning',
  INSTANCE_SCOPED_SLOTS: 'suppress-warning',
  OPTIONS_DATA_MERGE: 'suppress-warning',
  COMPONENT_V_MODEL: 'suppress-warning',
  CUSTOM_DIR: 'suppress-warning',
  INSTANCE_EVENT_EMITTER: 'suppress-warning',
  ATTR_FALSE_VALUE: 'suppress-warning',
  INSTANCE_ATTRS_CLASS_STYLE: 'suppress-warning',
  GLOBAL_PROTOTYPE: 'suppress-warning',
  GLOBAL_EXTEND: 'suppress-warning',
  GLOBAL_MOUNT: 'suppress-warning',
  OPTIONS_DESTROYED: 'suppress-warning',
  INSTANCE_DESTROY: 'suppress-warning',
});

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$laravel = window.Laravel;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

app.use(InstantSearch);
app.use(VueObserveVisibility);

app.use(BootstrapVue);
app.use(VueTextareaAutosize);
app.use(
  VueTippy,
  {
    directive: 'tippy',
    component: 'tippy',
    defaultProps: {
      placement: 'top',
      allowHTML: true,
      interactive: true,
      arrow: true,
    },
    popperOptions: {
      modifiers: {
        preventOverflow: {
          enabled: true,
        },
      },
    },
    onShow: (options) => !!options.props.content,
  },
);

// Lazy load
app.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

app.use(VueScrollTo, {});

library.add(faUndo, faQuestion, faQuestionCircle, faHourglassHalf, faTimesCircle, faEquals, faCheck, faTimes, faThumbsUp, faFrown, faTrash, faSignOutAlt, fasHeart, farHeart, faArrowLeft, faPaperPlane, faChevronLeft, faPen, faQuoteLeft, faArrowUpRightFromSquare, faEye, faEyeSlash, faInfoCircle, faInstagram, faLinkedin, faArrowDown, faArrowRight, faArrowUpFromBracket);

app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('Index', require('./index.vue').default);
app.component('TagList', require('./components/chatbot/tagList.vue').default);

// Chatbot Components
app.component('MultiCheck', require('./components/chatbot/multiCheck.vue').default);
app.component('SkillsCheckList', require('./components/chatbot/skillsCheckList.vue').default);

// Layout
app.component('HeaderProgress', require('./components/layout/headerProgress.vue').default);
app.component('WebsiteFooter', require('./components/layout/footer.vue').default);
app.component('Georges', require('./components/layout/georges.vue').default);
app.component('ChatbotIndex', require('./components/chatbot/chatbotIndex.vue').default);
app.component('TopPanel', require('./components/layout/topPanel.vue').default);
app.component('LoginModal', require('./components/auth/login.vue').default);
app.component('RegisterModal', require('./components/auth/register.vue').default);
app.component('InternalError500', require('./components/errors/500.vue').default);
app.component('InternalError503', require('./components/errors/503.vue').default);

// Auth
app.component('Login', require('./components/auth/login.vue').default);

// Vision trust
app.component('DataExchangeButton', require('./components/visiontrust/dataExchangeButton.vue').default);

// Slick carousel
app.component('VSelect', vSelect);

app.use(store);
app.use(router);
app.use(apolloProvider);
provideApolloClient(apolloClient);
app.use(createMetaManager({
  refreshOnceOnNavigation: true,
}));

app.mixin({
  created() {
    store.dispatch('userStore/fetchUserTokens');
  },
});

app.mount('#jobready');
