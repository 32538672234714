<template>
	<b-modal
		id="login-modal"
		header-class="modal-header j_font-color-base"
		:hide-footer="true"
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		modal-class="modal fade full-screen-modal"
	>
		<template #modal-header>
			<router-link
				type="button"
				class="modal-btn-close"
				:to="{ name: 'index' }"
				exact
				@click="emitCloseModalEvent"
			/>
		</template>
		<template #default>
			<auth-modal-body-wrapper
				title="DONNE À TON PARCOURS L’IMPACT QU’IL MERITE"
				george-image="/images/george-6.svg"
			>
				<template #content>
					<form>
						<j-form-input
							v-model="email"
							field-name="email"
							field-type="email"
							:errors="errors"
							placeholder="Email"
						/>
						<j-form-input
							v-model="password"
							field-name="password"
							field-type="password"
							:errors="errors"
							placeholder="Mot de passe"
						/>
						<div>
							<label
								for="token"
								class="mb-1 d-flex align-items-center j_paragraph_14"
							>
								As-tu un code ?
								<span
									v-tippy="{ placement: 'top' }"
									class="tooltip-info ml-1 mt-1"
									content="En ajoutant le code, ton nom, ton prénom et tes statistiques
							seront visibles par la structure. Elle n'aura pas accès à tes
							autres données personnelles."
								>
									i
								</span>
							</label>
							<div class="input__wrapper">
								<j-form-input
									v-model="token"
									field-name="token"
									field-type="text"
									placeholder="Exemple : JOBREADY"
									:errors="errors"
									@keyup="checkToken"
								/>
								<img
									v-if="token"
									class="check-img"
									:src="
										tokenValid == true
											? '/images/checkMark.svg'
											: '/images/crossMark.png'
									"
									alt="image checkmark"
								>
							</div>
						</div>
					</form>
					<div class="mt-4 text-center">
						<button
							:disabled="formIsNotValid"
							class="btn btn-primary font-weight-bold px-4"
							type="button"
							@click="submitForm"
						>
							Se connecter
						</button>
						<p class="j_paragraph_14 mt-3">
							Tu n’as pas encore de compte Jobready ?
							<router-link
								class="j_font-color-base j_font-weight-bold"
								:to="{ name: 'register' }"
								@click.native="dataLayerPush('clicCTADemarrerInscriptionMenu')"
							>
								Inscris toi
							</router-link>
						</p>
						<router-link
							type="button"
							class="
                  j_font-color-base j_font-weight-bold j_paragraph_14
                  forgot-pwd__link mt-3"
							:to="{ name: 'resetPwd' }"
							exact
							@click="emitCloseModalEvent"
						>
							Mot de passe oublié ?
						</router-link>
						<hr class="my-4">
						<div class="d-flex align-items-center justify-content-center flex-column flex-xl-row gap-2">
							<linkedin-button label="Se connecter avec Linkedin" />
							<visions-trust-login-button
								class="mt-0"
								label="Se connecter avec Visionstrust"
							/>
						</div>
					</div>
				</template>
			</auth-modal-body-wrapper>
		</template>
	</b-modal>
</template>
<script>
import AuthModalBodyWrapper from '_components/layout/authModalBodyWrapper.vue';
import jFormInput from '_components/layout/jFormInput.vue';
import LinkedinButton from '_components/layout/linkedinButton.vue';
import DataLayerPush from '_components/mixins/dataLayerPush';
import VisionsTrustLoginButton from '_components/layout/visionsTrustLoginButton.vue';

export default {
  components: {
    jFormInput, LinkedinButton, AuthModalBodyWrapper, VisionsTrustLoginButton,
  },

  mixins: [DataLayerPush],
  data() {
    return {
      showPassword: false,
      loading: false,
      email: '',
      password: '',
      remember: false,
      isEmailValid: true,
      linkSent: false,
      token: null,
      tokenValid: null,
      errors: null,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },

  computed: {
    formIsNotValid() {
      return !this.checkEmailValid() || !this.password;
    },
  },

  mounted() {
    const { email } = this.$route.query;
    if (email) {
      this.email = email;
    }
  },

  methods: {
    checkEmailValid() {
      return this.email.length === 0 ? false : this.reg.test(this.email);
    },

    sendActivationLink() {
      this.loading = true;

      axios
        .post('/api/send-activation-link', {
          email: this.email,
        })
        .then((response) => {
          this.resetSubmit();
          if (response.request.status === 200 && response.data.state === 'ok') {
            this.linkSent = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    async submitForm(e) {
      e.preventDefault();

      this.errors = null;

      try {
        this.loading = true;
        const response = await axios
          .post('/api/connexion', {
            email: this.email,
            password: this.password,
            remember: this.remember,
            token: this.tokenValid ? this.token : null,
          });
        if (
          response.request.status === 200
						&& response.data.state === 'ok'
        ) {
          this.dataLayerPushWithTextButton('connexion_reussie', 'Se connecter');
          this.$router.go(0);
        }
      } catch (error) {
        this.errors = error.response.data.errors;
        this.errors.email = [
          "L'adresse mail et le mot de passe ne correspondent pas.",
        ];
        this.errors.password = [
          "L'adresse mail et le mot de passe ne correspondent pas.",
        ];
      } finally {
        this.loading = false;
      }
    },

    emitCloseModalEvent() {
      this.$emit('modalClosed');
    },

    async checkToken() {
      this.tokenValid = null;
      if (!this.token) return;
      try {
        const response = await axios.get(`/api/checkToken/${this.token}`);
        this.tokenValid = response.data.isTokenValid;
      } catch (error) {
        this.errors.token = 'Une erreur est survenue, veuillez réessayer plus tard.';
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@import '_sass/_global.scss';
@import '_sass/vendors/_include-media.scss';

#login-modal___BV_modal_outer_ {
  z-index: 100 !important;
}

.register-form-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .george__img {
    display: none;
  }

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: 30% 60% 10%;
		gap: 3rem;
		height: 100%;
		.george__img {
			display: unset;
			grid-area: 1;
			align-self: center;

      img {
        width: 100%;
      }
    }
  }

	@include media('>=desktop') {
		display: grid;
		grid-template-columns: 20% 60% 20%;
		gap: 3rem;
		height: 100%;
		.george__img {
			display: unset;
			grid-area: 1;
			align-self: center;

			img {
				width: 100%;
			}
		}
		.register-form__container {
			display: flex;
			flex-direction: column;

			.register-form__container-body {
				display: grid;
				gap: 60px;
				align-items: center;
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-input__group {
    width: 100%;
  }
}

.input__wrapper {
  position: relative;

  .check-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    height: 15px;
  }
}

.forgot-pwd__link {
  text-decoration: underline;
}

.tooltip-info {
  width: 12px;
  height: 12px;
  font-size: 7px;
  background-color: $gray-700;
}
</style>
