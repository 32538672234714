<template>
	<a
		href="/auth/linkedin"
		class="btn btn-linkedin rounded-pill"
	>
		<span class="pr-1">
			<img
				src="/images/linkedin-colored.svg"
				class="pb-1"
				alt="linkedin-icon"
				height="15"
				width="15"
			>
		</span>
		<span>
			{{ label }}
		</span>
	</a>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-linkedin {
	border: 1px solid #005CC2;
	color: #005CC2;
	font-weight: 700;
}
</style>
