import { apolloClient } from '_graphql';
import checkUserDiagnosticCompleted from '_graphql/checkUserDiagnosticCompleted.gql';

export default async function diagnosticCompleted({ next, router }) {
  const { data } = await apolloClient.query({
    query: checkUserDiagnosticCompleted,
    fetchPolicy: 'network-only',
  });
  const response = await axios.get('/api/get-skip-check-diagnostic-completed');
  if (!data.checkUserDiagnosticCompleted && !response.data.skipCheckDiagnosticCompleted) {
    return router.push({ name: 'chatbotByMachineName', params: { machineName: 'diag_inscription' } });
  }

  return next();
}
