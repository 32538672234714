<template>
	<b-modal
		id="globalSelectExperiencesModal"
		header-class="modal-header text-gray"
		modal-class="newpwd-modal-content experience-select-modal"
		@hide="returnToMySteps"
	>
		<template #modal-header="{ hide }">
			<h5 class="modal-title">
				Choisis jusqu'à 3 expériences à mettre en avant
			</h5>
			<div class="d-md-none close__btn-wrapper">
				<div class="close__btn" @click="hide" />
			</div>
			<!--			<button
				type="button"
				class="close right-close d-md-none"
				data-dismiss="modal"
				aria-label="Close"
				@click="hide"
			>
				<font-awesome-icon :icon="['far', 'times-circle']" />
				<span class="sr-only">
					close
				</span>
			</button>-->
		</template>
		<template #default>
			<ul class="experiences__list">
				<li
					v-for="experience in experiences"
					:key="experience.id"
					class="cursor-pointer"
				>
					<input
						:id="experience.id"
						v-model="selectedExperiences"
						type="checkbox"
						:value="experience.id"
					>
					<label
						:for="experience.id"
						class="experience cursor-pointer w-100"
					>
						<div class="experience__picture">
							<img
								v-if="experience"
								:src="`${$laravel.expImagesFolder}/${experience.picture}`"
								:alt="experience.position"
								loading="lazy"
							>
						</div>
						<div class="experience__info">
							<div class="experience__date">
								{{ getDate(experience) }}
							</div>
							<div class="experience__name font-weight-bold my-2">
								{{ experience.scenario.role }}
							</div>
							<div class="experience__structure">
								{{
									experience.scenario.structure || experience.scenario.structure ? experience.scenario.structure + ',' : experience.scenario.structurePartner ? experience.scenario.structurePartner + ',' : null
								}}
								{{ experience.scenario.where }}
							</div>
						</div>
					</label>
				</li>
			</ul>
		</template>

		<template #modal-footer="{ hide }">
			<button
				class="btn btn-primary rounded-pill font-weight-bold"
				:disabled="!isAvailableToExport"
				type="button"
				@click.prevent="printPassport"
			>
				<span class="btn-icon icon-download" />
				Télécharger
			</button>
			<button
				type="button"
				class="btn-close d-none d-md-block"
				data-dismiss="modal"
				aria-label="Close"
				@click="hide"
			>
				<span class="sr-only">
					close
				</span>
			</button>
		</template>
	</b-modal>
</template>
<script>
import experienceDateFormatters from '../../mixins/experienceDateFormatters';

export default {
  mixins: [
    experienceDateFormatters,
  ],
  data() {
    return {
      selectedExperiences: [],
      experiences: [],
    };
  },
  computed: {
    isAvailableToExport() {
      return this.selectedExperiences && this.selectedExperiences.length <= 3 && this.selectedExperiences.length > 0;
    },
  },
  created() {
    this.getExperiences();
  },
  methods: {

    printPassport() {
      const query = new URLSearchParams();
      query.append('experiences', this.selectedExperiences);
      const url = `/soft-skill-synthesis/export?${query.toString()}`;
      window.location.href = url;
    },
    async getExperiences() {
      const response = await axios.post('/api/auth/experiences');
      this.experiences = response.data.experiences;
    },
    returnToMySteps() {
      if (this.$route.path === '/telecharger/synthese') {
        this.$router.push({ name: 'mySteps', params: { activeTab: 'me-preparer' } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "_sass/_global.scss";
@import "_sass/vendors/_include-media.scss";
#globalSelectExperiencesModal___BV_modal_outer_ { z-index: 10000 !important;}

.right-close {
	transform: translate(-50%, -50%);
}

.close__btn-wrapper {
	position: absolute;
	top: 10px;
	right: 20px;
}

.close__btn {
	position: relative;
	display: block;
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	cursor: pointer;
	z-index: 0;

	&:before,
	&:after {
		border-radius: 10px;
		display: block;
		content: "";
		height: 2px;
		width: 15px;
		background: black;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	&:after {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

.experiences__list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		background: white;
		padding: 15px;
		display: flex;
		align-items: center;
		gap: 15px;
		margin-bottom: 10px;

		.experience {
			display: flex;
			align-items: center;
			gap: 20px;

			img {
				width: 50px;
				height: 50px;
				object-fit: contain;
			}
		}
	}

	.experience__info {
		.experience__date {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-weight: 500;
			color: $dark;
			font-size: 10px;
			text-transform: uppercase;
			line-height: 12px;
		}

		.experience__structure{
			display: block;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			color: $dark;
			text-transform: uppercase;
		}

		.experience__name {
			transition: color .3s linear;
			display: block;
			font-size: 20px;
			letter-spacing: -.02rem;
			line-height: 1.2;
			color: #373f3f;
			text-align: left;
		}
	}
}
</style>
