<template>
	<!-- message group -->
	<div
		:id="isLast ? 'is-last' : null"
		class="message-block"
		:class="{ 'user-message': message.user }"
	>
		<span
			v-if="!message.user"
			class="cta-btn"
		>
			<span class="sr-only">
				Georges le Toucan
			</span>
			<img
				src="/images/mascotte-240.svg"
				alt="Georges"
			>
		</span>
		<div class="message-feed">
			<span
				v-if="message.user && endOfChatbot && isSecondLast"
				class="go-back"
				@click="previousQuestion()"
			>
				<span class="go-back-title">
					revenir ici
				</span>
				<img
					class="go-back-icon d-none"
					src="/images/undo.png"
					alt="undo prev question"
				>
			</span>
			<!-- eslint-disable vue/singleline-html-element-content-newline -->
			<div
				v-if="message.talk_msg"
				class="message-wrap"
			>
				<div class="single-message">{{ message.talk_msg }}</div>
			</div>
			<div class="message-wrap">
				<div v-if="message.label != null" class="single-message">{{ message.label }}</div>
				<span
					v-if="!message.user && isLast && helpQuestion"
					class="question-btn-wrapper"
				>
					<a
						v-if="callMachineName !== 'diagnostic'"
						href="#"
						class="question-btn"
						@click="$emit('showHelpMessage')"
					>
						<font-awesome-icon :icon="['far', 'question-circle']" />
					</a>
				</span>
			</div>
			<!-- eslint-enable vue/singleline-html-element-content-newline -->
		</div>
	</div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => ({
        user: false,
        talk_msg: '',
        label: '',
      }),
    },

    callMachineName: {
      type: String,
      required: true,
    },

    helpQuestion: {
      validator: (value) => value === null || value === undefined || typeof value === 'string',
      required: true,
    },

    endOfChatbot: {
      type: Boolean,
      required: true,
    },

    isLast: {
      type: Boolean,
      required: true,
    },

    isSecondLast: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      redirection: this.$route.query.redirect,
    };
  },

  methods: {
    previousQuestion() {
      this.$emit('previousQuestion');
    },
  },
};
</script>

<style scoped lang="scss">
.question-btn-wrapper {
	margin-left: 10px;

.question-btn {
	color: #007BB6;
}
}
</style>
