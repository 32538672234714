<template>
	<div class="data-exchange">
		<a
			href="#"
			class="btn btn-vision"
			@click.prevent="initDataExchange()"
		>
			{{ label }}
		</a>
		<!-- Export Popup -->
		<b-modal
			:id="exchangeModalId"
			hide-header-close
			title="VisionsModal"
		>
			<template #modal-header>
				<div class="row-cols-1 w-100 mx-0">
					<div class="row-cols-1 text-center">
						<img
							src="/images/logo-vision-rgb.png"
							class="logo-visions rounded text-center mx-auto d-block my-2"
							width="100"
							alt="Visions Logo"
						>
					</div>
					<div
						class="modal-content-text row d-flex justify-content-center mx-0"
					>
						<div class="tooltip-vision">
							<img
								class="tooltip-vision-img"
								src="/images/tooltip.svg"
								alt="Icône d'information"
							>
							<span class="tooltip-text">
								{{ form.infoText }}
							</span>
						</div>
					</div>
				</div>
			</template>

			<template #default>
				<div class="px-3">
					<div
						class="
              d-flex
              justify-content-center
              align-items-center
              flex-column flex-sm-row
              mb-3
            "
					>
						<img
							src="/images/logo-jobready-couleur.svg"
							class="logo rounded mx-auto d-block"
							width="150"
							alt="Jobready"
						>
						<img
							src="/images/right-arrow.png"
							class="icon-arrow rounded mx-auto d-block"
							width="50"
							alt="arrow"
						>
						<img
							:src="form.serviceImportLogo"
							class="logo rounded mx-auto d-block"
							alt="[Logo import]"
							width="150"
						>
					</div>
					<div
						v-for="data in form.datatypes"
						:key="data.id"
						class="d-flex justify-content-between flex-direction-column gap-2"
					>
						<div class="text-left">
							<h4 class="j_paragraph font-weight-bold text-uppercase">
								{{ data.name }}
							</h4>
							<p class="j_paragraph mx-0">
								{{ data.description }}
							</p>
						</div>
						<div class="custom-control custom-checkbox pr-0">
							<input
								:id="data.id"
								v-model="data.checked"
								type="checkbox"
								class="custom-control-input"
								checked
							>
							<label
								class="custom-control-label"
								:for="data.id"
							>
								{{ data.checked ? "OUI" : "NON" }}
							</label>
						</div>
					</div>
					<div class="custom-control custom-checkbox pr-0 pr-sm-5">
						<input
							id="has_account"
							v-model="form.isNewAccount"
							type="checkbox"
							class="custom-control-input"
						>
						<label
							class="custom-control-label w-unset"
							for="has_account"
						>
							Créer un nouveau compte avec cet e-mail ?
						</label>
					</div>
					<div
						v-if="errors"
						class="alert alert-danger"
					>
						Veuillez Cocher
						<strong>Créer un nouveau compte avec cet e-mail ?</strong> pour
						poursuivre
					</div>
				</div>
			</template>

			<template #modal-footer>
				<div
					class="w-100 d-flex gap-5 justify-content-center align-items-center"
				>
					<button
						type="button"
						class="btn btn-secondary j_paragraph"
						@click="closeModal"
					>
						Fermer
					</button>
					<button
						type="button"
						class="btn btn-vision j_paragraph text-white"
						@click.prevent="submitConsent"
					>
						Valider
					</button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'DÉVELOPPE TES SOFT SKILLS',
    },
    partner: {
      type: String,
      default: null,
    },
    logo: {
      type: String,
      default: null,
    },
    exchangeModalId: {
      type: String,
      default: 'exchangeModalId',
    },
  },

  data() {
    return {
      loading: false,
      emailImport: null,
      user: this.$laravel.user,
      form: {
        datatypes: [],
        consentResponse: null,
        isNewAccount: false,
        partner: null,
      },
      errors: null,
    };
  },

  methods: {
    initDataExchange() {
      this.loading = true;
      axios
        .post('/api/visiontrust', { partner: this.partner })
        .then((response) => {
          this.form.consentResponse = response.data;
          this.form.datatypes = response.data.datatypes;
          this.form.infoText = response.data.infoText;
          this.form.serviceImportLogo = this.partner !== 'orientoi'
            ? response.data.serviceImportLogo
            : '/images/logo-orientoi-rgb.png';
          this.$bvModal.show(this.exchangeModalId);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    submitConsent() {
      this.loading = true;
      this.form.partner = this.partner;
      if (this.emailImport) {
        this.form.consentResponse.emailImport = this.emailImport;
      } else {
        this.form.consentResponse.emailImport = this.user.email;
      }

      axios
        .post('/api/visiontrust/submit-consent', this.form)
        .then((response) => {
          if (response.data.redirectionUrl) {
            window.location.href = response.data.redirectionUrl;
          }
          this.loading = false;
          this.$bvModal.hide(this.exchangeModalId);
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.loading = false;
        });
    },

    closeModal() {
      this.form.consentResponse = null;
      this.form.datatypes = null;
      this.$bvModal.hide(this.exchangeModalId);
    },
  },
};
</script>

<style lang="scss">
@import "_sass/vendors/_include-media.scss";
#orientoi-exchange-modal___BV_modal_outer_ {
  z-index: 1000 !important;
  position: unset !important;
}
#orientoi-exchange-modal {
  .modal-header {
    background: #21bbee;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
  .logo {
    height: 2.5rem;
  }

  .icon-arrow {
    height: 2rem;
  }

  .custom-control-label {
    width: 40px;
  }
  .custom-control.custom-checkbox {
    background: unset;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #21bbee;
    background-color: #21bbee;
  }
  .modal-content {
    max-width: 60%;
    border-radius: 25px;
  }
  .tooltip-vision {
    max-height: 100px;
    overflow: scroll;
  }

  .tooltip-text {
    font-size: 14px;
  }
  @include media("<=tablet") {
    .modal-content {
      max-width: 100%;
      height: 100vh;
    }
    .modal-header,
    .modal-footer,
    .modal-content {
      border-radius: 0;
    }
  }
}
</style>
