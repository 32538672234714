<template>
	<div
		v-show="isShow"
		class="auth-menu"
		@click="handleExitMenu"
	>
		<div class="auth-menu__wrapper">
			<div class="auth-menu__header mb-4">
				<div class="d-md-none close__btn-wrapper">
					<div class="close__btn" @click="() => $emit('exit')" />
				</div>
				<div class="user-info d-flex align-items-center justify-content-center flex-column">
					<div class="d-flex align-items-center justify-content-center">
						<div class="user-avatar mr-2">
							<img
								:src="authUser && authUser.picture ? `${$laravel.avatarsFolder}/${authUser.picture}` : '/images/Mascotte-243.svg'"
								:srcset="authUser && authUser.picture ? `${$laravel.avatarsFolder}/${authUser.picture} 2x` : '/images/Mascotte-243.svg 2x'"
								alt="photo de profil"
								loading="lazy"
							>
						</div>
						<div class="auth__name">
							{{ authUser.full_name }}
						</div>
					</div>
					<div class="mt-2 j_paragraph_14">
						🎓 {{ authUser.study_formation }} , {{ authUser.studies_level }}
					</div>
					<div v-if="need" class="mt-2 j_paragraph_14">
						🎯 {{ need }}
					</div>
				</div>
			</div>
			<div class="auth-menu-body d-flex align-items-center flex-column">
				<div class="auth-menu-content">
					<div class="d-flex align-items-center justify-content-center gap-1 mb-5">
						<button
							class="data-count"
							type="button"
							@click="checkSamePage('/profil/mes-experiences', 'mes-experiences')"
						>
							<div class="d-flex flex-column">
								<div class="data-count__value">
									{{ experiencesCount }}
								</div>
								<span class="auth-menu__text-info d-flex align-items-center">
									Expérience{{ experiencesCount > 1 ? 's' : '' }}
									<i class="arrow" />
								</span>
							</div>
						</button>
						<button
							class="data-count"
							type="button"
							@click="checkSamePage('/profil/mes-badges', 'mes-badges')"
						>
							<div class="d-flex flex-column">
								<div class="data-count__value">
									{{ badgesCount }}
								</div>
								<span class="auth-menu__text-info d-flex align-items-center">
									Badge{{ badgesCount > 1 ? 's' : '' }}
									<i class="arrow" />
								</span>
							</div>
						</button>
					</div>
					<div class="quickly__actions">
						<tippy v-if="experiencesCount < 3">
							<template #default>
								<button :class="`btn btn-primary rounded-pill btn--disabled`" type="button">
									<span class="btn-icon icon-download" />
									Ma synthèse
								</button>
							</template>
							<template #content>
								<div class="d-flex flex-column justify-content-center align-items-center">
									Raconte 3 expériences pour télécharger ta synthèse.
									<router-link
										:to="{
											name: 'chatbotByMachineName',
											params: {
												machineName: 'experience',
											},
										}"
									>
										Raconte une expérience
									</router-link>
								</div>
							</template>
						</tippy>
						<button v-else :class="`btn btn-primary rounded-pill`" type="button" @click="exportSynthesis">
							<span class="btn-icon icon-download" />
							Ma synthèse
						</button>
						<tippy v-if="citizenExperiencesCount === 0">
							<template #default>
								<button	:class="`btn btn-primary rounded-pill btn--disabled`"	type="button">
									<span class="btn-icon icon-download" />
									Mon passeport engagement
								</button>
							</template>
							<template #content>
								<div class="d-flex flex-column justify-content-center align-items-center">
									Raconte une expérience d’engagement pour télécharger ton passeport engagement.<br>
									<router-link
										:to="{
											name: 'chatbotByMachineName',
											params: {
												machineName: 'experience',
											},
										}"
									>
										Raconte une expérience
									</router-link>
								</div>
							</template>
						</tippy>
						<button v-else :class="`btn btn-primary rounded-pill`" type="button" @click="exportPassport">
							<span class="btn-icon icon-download" />
							Mon passeport engagement
						</button>
					</div>
				</div>
			</div>

			<div class="auth-menu-footer d-flex align-items-center justify-content-center flex-column">
				<div class="auth-menu-links">
					<span class="mb-2" @click="redirectTo('mySettings')">
						<i class="icon-arrow-r" />Mes paramètres
					</span>
					<a href="#" @click="logout">
						<i class="icon-arrow-r" />Déconnexion
					</a>
				</div>
			</div>
		</div>
		<global-experiences-select-modal />
		<experiences-select-modal />
	</div>
</template>

<script>
import ExperiencesSelectModal from '_components/profil/experiencesSelectModal.vue';
import globalExperiencesSelectModal from '_components/profil/modals/globalExperiencesSelectModal.vue';
import DataLayerPush from '_components/mixins/dataLayerPush';
import authUserNeedQuery from '_graphql/getAuthUserNeedQuery.gql';
import { mapActions } from 'vuex';
import { Tippy } from 'vue-tippy';

export default {
  components: { Tippy, globalExperiencesSelectModal, ExperiencesSelectModal },
  mixins: [DataLayerPush],
  props: {
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      experiences: [],
      citizenExperiences: [],
      need: null,
    };
  },

  computed: {
    badgesCount() {
      return this.$store.getters['jobreadynessStore/getBadgesCount'];
    },
    experiencesCount() {
      return this.$store.getters['jobreadynessStore/getExperiencesCount'];
    },
    citizenExperiencesCount() {
      return this.$store.getters['jobreadynessStore/getCitizenExperiencesCount'];
    },
    authUser() {
      return this.$store.getters['userStore/getUser'];
    },
    a1ConnectIsEnabled() {
      return this.$laravel.a1ConnectEnabled;
    },
  },

  async created() {
    this.fetchJobreadynessData();
    this.getAuthUserNeed();
  },

  methods: {
    ...mapActions('jobreadynessStore', ['fetchJobreadynessData']),

    async getAuthUserNeed() {
      try {
        const { data } = await this.$apollo.query({
          query: authUserNeedQuery,
          fetchPolicy: 'network-only',
        });
        this.need = data?.authUserNeed?.name;
      } catch (error) {
        console.log(error);
      }
    },

    async exportSynthesis() {
      this.dataLayerPushWithTextButton('menu_clics', 'Ma synthèse');
      this.dataLayerPush('ExportCVmenu');
      if (this.experiencesCount === 3) {
        window.location.href = '/soft-skill-synthesis/export';
      } else if (this.experiencesCount > 3) {
        this.$emit('hideMenu');

        this.$bvModal.show('globalSelectExperiencesModal');
      }
    },

    async exportPassport() {
      this.dataLayerPushWithTextButton('menu_clics', 'Mon passeport engagement');
      this.dataLayerPush('ExportPEmenu');
      if (this.citizenExperiencesCount <= 3 && this.citizenExperiencesCount !== 0) {
        window.location.href = '/commitment-passport/export';
      } else if (this.citizenExperiencesCount > 3) {
        this.$emit('hideMenu');
        this.$bvModal.show('selectExperiencesModal');
      }
    },

    logout() {
      localStorage.auth = false;
      axios.post('/api/deconnexion').then(() => {
        if (this.a1ConnectIsEnabled) {
          window.location.href = '/a1-connect/logout';
          return;
        }
        window.location.href = '/';
      }).catch((error) => {
        // TODO handle errors correctly
        console.log(error);
      });
    },

    checkSamePage(link, page) {
      this.dataLayerPushWithTextButton('menu_clics', page);
      this.$emit('exit');
      if (this.$route.path.includes(link)) {
        return;
      }
      this.$router.push({ name: 'mySoftSkillsAndMyJourney', params: { activePage: page } });
    },

    redirectTo(to) {
      this.$emit('exit');
      this.$router.push({ name: to });
    },
    handleExitMenu(e) {
      if (e.target.classList.contains('auth-menu')) {
        this.$emit('exit');
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import "_sass/_global.scss";
@import "_sass/vendors/_include-media.scss";

.auth-menu {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  @include media('>=tablet') {
    left: unset;
  }
}

.auth-menu__wrapper {
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 220px auto 80px;
  grid-template-areas:
		"header"
		"body"
		"footer";
  background: $primary;
  width: 100%;

  @include media('>=tablet') {
    grid-template-rows: 220px auto 80px;
    width: 400px;
  }
}

.auth-menu__header {
  background-color: rgba(255, 255, 255, 0.3)!important;
  padding: 45px 15px 15px;
  grid-area: header;
}

.auth-menu-body {
  overflow-y: scroll;
  grid-area: body;
  min-height: auto;
}

.auth-menu-footer {
  grid-area: footer;
  padding-bottom: 20px;
}

.user-avatar {
  border-radius: 50%;
  min-height: 60px;
  min-width: 60px;
  width: 60px;
  height: 60px;
  cursor: pointer;

  img {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
  }
}

.auth-menu__header {
  width: 100%;
}

.auth-menu__text-info {
  font: $font-family-sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
}

.auth-menu__body {
  overflow-y: scroll;
  min-height: auto;
}

.auth-menu-content {
  width: 300px;
}

.close__btn {
  position: relative;
  display: block;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 0;

  &:before,
  &:after {
    border-radius: 10px;
    display: block;
    content: "";
    height: 2px;
    width: 15px;
    background: black;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.btn-primary {
  border: 1px solid $gray-900;
}

.auth__name {
  font-family: $font-family-bebas;
  font-size: 44px;
  line-height: 40px;
  font-weight: 700;
}

.quickly__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}

.auth-menu-links {
  width: 300px;
  a, span {
    color: $gray-900;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.close__btn-wrapper {
  position: absolute;
  top: 10px;
  right: 20px;
}

.arrow {
  box-sizing: border-box;
  height: 8px;
  width: 8px;
  border-style: solid;
  border-color: black;
  border-width: 0px 1px 1px 0px;
  transform: rotate(-45deg);
}

.data-count {
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  min-width: 139.8px;
  height: 80px;
  background: $white;
  color: $gray-900;
  font-size: 12px;
  &:hover { text-decoration: none;}
  .data-count__value {
    font-family: $font-family-bebas;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: $gray-900;
  }
}
</style>
