import {
  ApolloClient, ApolloLink, InMemoryCache, HttpLink,
} from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { onError } from 'apollo-link-error';

const httpLink = new HttpLink({
  uri: '/graphql',
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ));
  }
  if (networkError) {
    if (networkError.statusCode === 500) {
      document.open();
      document.write(`${networkError.response.status} ${networkError.response.statusText}`);
      document.close();
    } else if (networkError === 401) {
      // window.location.href = '/api/security/logout';  A remettre quand la logique d'authentification est mise en place
    }
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
  operation.setContext({
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-XSRF-TOKEN': decodeURIComponent(cookieValue),
    },
  });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink, onErrorLink),
  cache: new InMemoryCache(),
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export default apolloProvider;
