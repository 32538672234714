import { apolloClient } from '../graphql';
import checkUserRegistered from '../graphql/checkUserRegistered.gql';

export default async function profilCompleted({ next, router }) {
  const { data } = await apolloClient.query({
    query: checkUserRegistered,
    fetchPolicy: 'network-only',
  });
  if (!data.checkUserRegistered) {
    const prevPath = router.currentRoute.value.path;
    return router.push({ name: 'chatbotByMachineName', params: { machineName: 'profil' }, query: { redirect: prevPath } });
  }

  return next();
}
