/* eslint-disable */
export default {
  data() {
    return {
      deferredPrompt: null
    }
  },
  computed: {
    isMobileDevice() {
      if (navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.deferredPrompt = window.deferredPrompt;
  },
  methods: {
    shareToContact(shareText) {
      if (navigator.share) {
        navigator.share({
          title: 'Demander à mes contacts',
          text: shareText,
        });
      }
    },

    buildShareText(experienceRole, evaluationUrl, fullName, skillCount) {
      if (skillCount > 1) {
        return `Bonjour, je souhaiterais avoir votre avis sur mes compétences : ${evaluationUrl}\nIl s’agit d’évaluer ${skillCount} de mes soft skills acquises lors de mon expérience ${experienceRole}. Cela ne prend que 5 minutes, aucun compte à créer et c’est un vrai plus qui m’aidera dans mes candidatures.\n Merci, ${fullName}`;
      }
      return `Bonjour, je souhaiterais avoir votre avis sur mes compétences : ${evaluationUrl}\nIl s’agit d’évaluer une de mes soft skills acquises lors de mon expérience ${experienceRole}. Cela ne prend que 5 minutes, aucun compte à créer et c’est un vrai plus qui m’aidera dans mes candidatures.\n\n Merci, ${fullName}`;
    },
    async install() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          this.deferredPrompt = null;
        }
      }
    },
  },
};
