<template>
	<div class="tags-list-wrapper date-wrapper">
		<form
			class="date-widget-form"
			action="#"
		>
			<select
				v-if="options.days"
				v-model="responseDay"
				class="custom-select date-widget-select"
				@change="activateValidationBtn"
			>
				<option hidden>
					Jour
				</option>
				<option
					v-for="(day, dayKey) in days"
					:key="dayKey"
					:value="day"
				>
					{{ day }}
				</option>
			</select>
			<select
				v-model="responseMonth"
				class="custom-select date-widget-select"
				@change="activateValidationBtn"
			>
				<option hidden>
					Mois
				</option>
				<option
					v-for="(month, monthKey) in months"
					:key="monthKey"
					:value="monthKey + 1"
				>
					{{ month }}
				</option>
			</select>

			<select
				v-model="responseYear"
				class="custom-select date-widget-select"
				@change="activateValidationBtn"
			>
				<option hidden>
					Année
				</option>
				<option
					v-for="(year, yearKey) in years"
					:key="yearKey"
					:value="year"
				>
					{{ year }}
				</option>
			</select>

			<div class="bottom-block mb-2">
				<button
					class="btn btn-primary btn-chatbot btn-date-widget rounded-pill"
					type="submit"
					:disabled="!validate"
					@click="response"
				>
					Valider
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    questionKey: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      responseMonth: 'Mois',
      responseYear: 'Année',
      responseDay: 'Jour',
      months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      minDate: null,
      validate: false,
    };
  },
  computed: {
    // Get years as array from now to -100 of the current year or from startDate if it exists
    years() {
      const year = new Date().getFullYear();
      const minYear = year - 100;
      const currentDate = new Date();
      if (this.minDate && this.minDate < currentDate) {
        return Array.from({ length: year - this.minDate.year() + 1 }, (value, index) => this.minDate.year() + index).reverse();
      }
      return Array.from({ length: year - minYear + 1 }, (value, index) => minYear + index).reverse();
    },
  },
  created() {
    if (this.options.minTimestamp) {
      this.minDate = moment.unix(this.options.minTimestamp);
    }
  },
  methods: {
    response(e) {
      e.preventDefault();
      // Get the key of the current question and the element selected By the user
      const data = {
        key: this.questionKey,
        value: this.responseDay !== 'Jour'
		        ? `${this.responseYear}-${this.responseMonth}-${this.responseDay}`
		        : `${this.responseYear}-${this.responseMonth < 10 ? '0' : ''}${this.responseMonth}`,
      };

      if (this.questionKey === 'birthdate') {
        data.value = `${this.responseYear}-${this.responseMonth < 10 ? '0' : ''}${this.responseMonth}-${this.responseDay}`;
      }

      // Set the text message as object
      const message = {
        label: this.responseDay !== 'Jour' ? `${this.responseDay} ${this.months[this.responseMonth - 1]} ${this.responseYear}` : `${this.months[this.responseMonth - 1]} ${this.responseYear}`,
        user: true,
        key: this.questionKey,
      };
      // Emit event to call 'pushMessage' function in parent component with the new message created
      this.$emit('pushMessage', message);

      // Emit Event to call 'disableInputs' function in parent component to disable the widget
      this.$emit('disableSelectDate');

      // Emit event to call 'response' function in parent component and send data to it
      this.$emit('answered', data);
    },
    activateValidationBtn() {
      this.validate = this.responseMonth !== 'Mois' && this.responseYear !== 'Année';
    },
  },
};
</script>
