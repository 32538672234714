<template>
	<div id="wrapper">
		<georges :closable="false" />
	</div>
</template>
<script>
export default {
  mounted() {
    $('.modal').modal({
      backdrop: 'static',
      keyboard: false,
    });
  },
};
</script>
