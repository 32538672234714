<template>
	<div class="top-panel">
		<div class="container">
			<resetpwd-modal />
			<login-modal @modalClosed="modalClosed" />
			<register-modal @modalClosed="modalClosed" />
			<new-pwd-modal />
			<header-logout :landing-page="landingPage" />
			<!-- main site navigation -->
		</div>
	</div>
</template>
<script>

import loginModal from '_components/auth/login.vue';
import registerModal from '_components/auth/register.vue';
import resetPwdModal from '_components/auth/resetPwd.vue';
import newPwdModal from '_components/auth/newPwd.vue';
import HeaderLogout from '_components/logout/header.vue';

export default {
  components: {
    'login-modal': loginModal,
    'register-modal': registerModal,
    'resetpwd-modal': resetPwdModal,
    'new-pwd-modal': newPwdModal,
    'header-logout': HeaderLogout,
  },
  directives: {
    'click-outside': {
      bind(el, binding, vNode) {
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          console.warn(warn);
        }
        const { bubble } = binding.modifiers;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;

        document.addEventListener('click', handler);
      },

      unbind(el) {
        document.removeEventListener('click', el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },

  props: {
    landingPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menuIcon: true,
      isAuthentified: this.$laravel.user,
      loginModalOpen: false,
      registerModalOpen: false,
      user: this.$laravel.user,
    };
  },

  methods: {
    logout(e) {
      e.preventDefault();
      localStorage.auth = false;
      axios.post('/api/deconnexion').then(() => {
        window.location.href = '/';
      }).catch((error) => {
        // TODO handle errors correctly
        console.log(error);
      });
    },

    setLoginActiveHeader() {
      this.loginModalOpen = true;
    },

    setRegisterActiveHeader() {
      this.registerModalOpen = true;
    },

    modalClosed() {
      this.loginModalOpen = false;
      this.registerModalOpen = false;
    },
  },
};
</script>
