<template>
	<b-modal
		id="register-modal"
		header-class="modal-header j_font-color-base"
		:hide-footer="true"
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		modal-class="modal fade full-screen-modal"
	>
		<template #modal-header>
			<router-link
				class="modal-btn-close"
				:to="{ name: 'index' }"
			/>
		</template>
		<template #default>
			<auth-modal-body-wrapper
				title="DECOUVRE TES PREMIERES SOFT SKILLS EN 15 MINUTES"
				george-image="/images/george-5.svg"
			>
				<template #content>
					<form>
						<j-form-input
							v-model="email"
							:errors="errors"
							field-type="email"
							field-name="email"
							placeholder="Email"
						/>
						<j-form-input
							v-model="pwd"
							:errors="errors"
							field-type="password"
							field-name="password"
							help-text="Le mot de passe doit comporter 8 caractères minimum"
							placeholder="Mot de passe"
						/>
						<div>
							<label
								for="token"
								class="mb-1 d-flex align-items-center j_paragraph_14"
							>
								As-tu un code ?
								<span
									v-tippy="{ placement: 'top' }"
									class="tooltip-info ml-1"
									content="En ajoutant le code, ton nom, ton prénom et tes statistiques
							seront visibles par la structure. Elle n'aura pas accès à tes
							autres données personnelles."
								>
									i
								</span>
							</label>
							<div class="input__wrapper">
								<j-form-input
									v-model="token"
									field-name="token"
									field-type="text"
									placeholder="Exemple : JOBREADY"
									:errors="errors"
									@keyup="checkToken"
								/>
								<img
									v-if="token"
									class="check-img"
									:src="
										tokenValid == true
											? '/images/checkMark.svg'
											: '/images/crossMark.png'
									"
									alt="image checkmark"
								>
							</div>
						</div>
						<div class="cgu-block mt-4">
							<j-checkbox-input
								v-model="isCguAccepted"
								field-name="cgu"
								label="J'AI LU ET J'ACCEPTE LES CGU ET POLITIQUE DE CONFIDENTIALITÉ"
								:required="true"
							/>
							<j-checkbox-input
								v-model="isNewsLetterSubscribed"
								field-name="isNewsLetterSubscribed"
								label="JE VEUX RECEVOIR LA NEWSLETTER JOBREADY"
								:required="false"
							/>
						</div>
						<!-- <div class="recaptcha-container text-center mt-4">
							<vue-recaptcha
								class="recaptcha"
								size="200"
								:sitekey="sitekey"
								:load-recaptcha-script="true"
								@verify="onVerify"
								@expired="onExpired"
							/>
						</div> -->
					</form>
					<div class="mt-4 text-center">
						<button
							:disabled="formNotValid"
							class="btn btn-primary font-weight-bold px-4"
							type="button"
							@click="submitSubscriptionForm"
						>
							S’inscrire
						</button>
						<p class="j_paragraph_14 mt-3">
							Tu as déjà un compte Jobready ?
							<router-link
								class="j_font-color-base j_font-weight-bold"
								:to="{ name: 'login' }"
							>
								Connecte toi
							</router-link>
						</p>
						<hr>
						<linkedin-button
							label="S'inscrire avec Linkedin"
							class="mt-2"
						/>
					</div>
				</template>
			</auth-modal-body-wrapper>
		</template>
	</b-modal>
</template>
<script>
// import VueRecaptcha from 'vue-recaptcha';
import jFormInput from '../layout/jFormInput.vue';
import JCheckboxInput from '../layout/jCheckboxInput.vue';
import LinkedinButton from '../layout/linkedinButton.vue';
import AuthModalBodyWrapper from '../layout/authModalBodyWrapper.vue';

export default {
  components: {
    jFormInput,
    // VueRecaptcha,
    JCheckboxInput,
    LinkedinButton,
    AuthModalBodyWrapper,
  },
  data() {
    return {
      recaptchaVerified: null,
      sitekey: this.$laravel.recaptcha.siteKey,
      loading: false,
      email: '',
      pwd: '',
      tokenValid: null,
      errors: {
        email: null,
        password: null,
        token: null,
      },
      isCguAccepted: false,
      isNewsLetterSubscribed: false,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      token: '',
      limitUsersReached: false,
      maVoieUserId: null,
      maVoieStepId: null,
      consentId: null,
      visions: null,
    };
  },

  computed: {
    formNotValid() {
      return (
        this.loading
					|| !this.recaptchaVerified
					|| !this.isCguAccepted
					|| !this.email
					|| !this.pwd
      );
    },
  },

  mounted() {
    const mavoieToken = 'MAVOIE_API';
    const visionsToken = 'API_VISIONS';
    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    const maVoieUserId = url.searchParams.get('maVoieId');
    const maVoieStepId = url.searchParams.get('stepId');
    this.email = email || null;
    this.emailConfirmation = email || null;
    this.maVoieUserId = maVoieUserId || null;
    this.maVoieStepId = maVoieStepId || null;
    this.token = email && maVoieUserId && maVoieStepId ? mavoieToken : null;
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      this.checkToken();
    }
    if (this.$route.query.consentId && this.$route.query.visions) {
      this.consentId = this.$route.query.consentId;
      this.visions = this.$route.query.visions;
      this.token = visionsToken;
    }
  },

  methods: {
    submitSubscriptionForm(e) {
      window.dataLayer.push({
        event: 'clicCTASInscrire',
      });

      e.preventDefault();

      this.loading = true;

      // Resetting errors when submitting
      this.errors = {
        email: null,
        email_confirmation: null,
        password: null,
        password_confirmation: null,
        token: null,
      };

      if (this.isCguAccepted) {
        axios
          .post('/api/register', {
            email: this.email.trim(),
            email_confirmation: this.email.trim(),
            password: this.pwd,
            password_confirmation: this.pwd,
            newsLetterSubscription: this.isNewsLetterSubscribed,
            token: this.token,
            maVoieUserId: this.maVoieUserId,
            maVoieStepId: this.maVoieStepId,
            consentId: this.consentId,
            visions: this.visions,
          })
          .then((response) => {
            this.loading = false;
            if (
              response.request.status === 200
									&& response.data.state === 'ok'
            ) {
              this.dataLayerPush('inscription_jobready');
              this.$router.go(0);
            } else if (response.data.state === 'ko') {
              this.limitUsersReached = true;
              this.pwd = '';
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.errors) {
              this.pwd = '';
			  this.pwdConfirmation = '';
              this.errors = error.response.data.errors;
            } else {
              console.log(error);
            }
          });
      } else {
        this.loading = false;
      }
    },
    emitCloseModalEvent() {
      this.$emit('modalClosed');
    },
    onVerify() {
      this.recaptchaVerified = true;
    },
    onExpired() {
      this.recaptchaVerified = null;
    },

    async checkToken() {
      this.tokenValid = null;
      if (!this.token) return;
      try {
        const response = await axios.get(`/api/checkToken/${this.token}`);
        this.tokenValid = response.data.isTokenValid;
      } catch (error) {
        this.errors.token =						'Une erreur est survenue, veuillez réessayer plus tard.';
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@import '_sass/_global.scss';
@import '_sass/vendors/_include-media.scss';

#register-modal___BV_modal_outer_ {
	z-index: 100 !important;
}

.container {
	padding: 45px 30px;
	padding-top: 0;
}

.register-form-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.george__img {
		display: none;
	}

	@include media('>=desktop') {
		display: grid;
		grid-template-columns: 20% 65% 15%;
		gap: 3rem;
		height: 100%;
		.george__img {
			display: unset;
			grid-area: 1;
			align-self: center;

			img {
				width: 100%;
			}
		}
		.register-form__container {
			display: flex;
			flex-direction: column;

			.register-form__container-body {
				display: grid;
				gap: 60px;
				align-items: center;
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}

.register-form {
	display: flex;
	flex-direction: column;
	align-items: center;

	.form-input__group {
		width: 100%;
	}
}

.input__wrapper {
	position: relative;

	.check-img {
		position: absolute;
		top: 10px;
		right: 15px;
		height: 20px;
	}
}

.tooltip-info {
  width: 12px;
  height: 12px;
  font-size: 7px;
  background-color: $gray-700;
}
</style>
