import { apolloClient } from '_graphql';
import authUserQuery from '_graphql/authUser.gql';

const state = {
  user: {},
  userTokens: [],
};

const mutations = {
  SET_USER(stateObj, payload) {
    stateObj.user = payload;
  },
  SET_USER_TOKENS(stateObj, payload) {
    stateObj.userTokens = payload;
  },

};

const actions = {
  async fetchAuthUser({ commit }) {
    try {
      const { data } = await apolloClient.query({
        query: authUserQuery,
        fetchPolicy: 'network-only',
      });
      commit('SET_USER', data.authUser);
    } catch (error) {
      this.error(error);
    }
  },

  async updateUserInfo({ dispatch }, payload) {
    await axios.put(`/user/${state.user.id}`, payload);
    dispatch('fetchAuthUser');
  },

  async fetchUserTokens({ commit }) {
    try {
      const { data } = await axios.get('/api/user/tokens');
      commit('SET_USER_TOKENS', data.tokens);
    } catch (error) {
      commit('SET_USER_TOKENS', []);
    }
  },
};

const getters = {
  getUserTokens: (stateObj) => stateObj.userTokens,
  getUser: (stateObj) => stateObj.user,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
