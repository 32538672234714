import { apolloClient } from '_graphql';
import userIsConnected from '_graphql/userIsConnected.gql';

export default async function auth({ next, router }) {
  const { data } = await apolloClient.query({
    query: userIsConnected,
    fetchPolicy: 'network-only',
  });
  if (!data.userIsConnected) {
    return router.push({ name: 'a1-connect' });
  }

  return next();
}
