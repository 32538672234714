<template>
	<div class="chat-form-wrapper">
		<div
			class="chat-form"
		>
			<form @submit.prevent="">
				<div class="input-holder">
					<input
						v-model="token"
						type="text"
						:placeholder="widgetProps.placeholder"
						class="form-control chatbot-textarea"
						@input="checkToken"
					>
					<img
						v-if="token"
						class="check-img"
						:src="
							tokenValid == true
								? '/images/checkMark.svg'
								: '/images/crossMark.png'
						"
						alt="image checkmark"
					>
				</div>
			</form>
		</div>
		<div class="bottom-block-wrapper d-flex justify-content-center mb-2">
			<div
				v-if="widgetProps.none"
				class="bottom-block d-flex justify-content-center mb-2"
			>
				<button
					class="btn mr-2 btn-chatbot btn-secondary-chatbot"
					type="submit"
					style="float: left"
					@click="submitEmptyValue"
				>
					{{ widgetProps.none }}
				</button>
			</div>
			<div class="text-center">
				<button
					type="submit"
					:disabled="!tokenValid"
					class="btn btn-primary btn-chatbot"
					@click="submitEnterValue"
				>
					Valider
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  props: {
    widgetProps: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tokenValid: false,
      token: null,
    };
  },

  methods: {

	  submitEnterValue(e) {
		  this.$emit('input', this.token);
		  this.$emit('click', e);
	  },

	  submitEmptyValue(e) {
		  this.$emit('empty-value', e);
	  },
	  async checkToken() {
		  this.tokenValid = null;
		  if (!this.token) return;
		  try {
			  const response = await axios.get(`/api/checkToken/${this.token}`);
			  this.tokenValid = response.data.isTokenValid;
		  } catch (error) {
			  console.log(error);
		  }
	  },
  },
};
</script>
<style lang="scss" scoped>
.search-results-list {
    padding: 0;
    display: flex;
    align-items: center;
	flex-wrap: wrap;
    gap: 20px;

	.btn {
		white-space: nowrap;
	}
 }

 .check-img {
	position: absolute;
		top: 50%;
		right: 10px;
		width: 20px;
		height: 20px;
		bottom: 50%;
		transform: translateY(-50%);
 }
</style>
