<template>
	<div
		ref="skillsCheckList"
		class="message-block user-message"
	>
		<!-- chat cta area -->
		<div class="chat-cta-block style-2">
			<form action="#">
				<div
					class="title-holder"
				>
					<strong>
						Tu peux encore choisir {{ remainsSkillsToBeSelected }} soft skills
					</strong>
				</div>
				<div class="content-holder">
					<div class="soft-skills-grid">
						<div
							v-for="(value, key) in skillList"
							:id="'skill-' + key"
							:key="key"
							class="soft-skills-checkbox"
							@click.stop="scrollToEnd"
						>
							<font-awesome-icon
								v-tippy="{ placement: 'top' }"
								:content="`<strong>${
									value[1].skill.name ? value[1].skill.name : ''
								}</strong> <br/> <p>${value[1].skill.description}</p>`"
								class="soft-skill-info"
								icon="fas fa-info-circle"
							/>
							<input
								:id="key"
								v-model="responses"
								type="checkbox"
								class="soft-skills-checkbox-input"
								:value="value[1].skill.id"
								hidden
								:disabled="disableInputs(value[1].skill.id)"
							>
							<label
								:for="key"
								class="d-flex align-items-center justify-content-center"
							>
								<!-- skill block sm -->
								<span class="skill-block">
									<span class="inner-block">
										<span class="inner-holder">
											<strong class="title">
												{{ value[1].skill.name ? value[1].skill.name : "" }}
											</strong>
										</span>
									</span>
									<span class="img-holder">
										<img
											:src="`${$laravel.novaUploadsFolder}/${value[1].skill.image}`"
											:srcset="`${$laravel.novaUploadsFolder}/${value[1].skill.image} 2x`"
											:alt="value[1].skill.name ? value[1].skill.name : ''"
											loading="lazy"
										>
									</span>
								</span>
							</label>
						</div>
					</div>

					<div class="show-more">
						<a
							v-if="showSeeMore"
							href="#"
							@click="seeMore"
						>
							en voir plus
						</a>
					</div>
				</div>

				<div
					class="bottom-block-wrapper d-flex justify-content-center mb-2 mt-5"
				>
					<div class="text-center">
						<button
							class="btn btn-primary btn-chatbot"
							type="submit"
							:disabled="remainsSkillsToBeSelected >= 6"
							@click="response"
						>
							Valider
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    questionKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      skillsArray: [],
      skillList: [],
      showSeeMore: true,
      matchedSkills: [],
      selectedItemsLength: 6,
      responses: [],
    };
  },
  computed: {
    remainsSkillsToBeSelected() {
      return this.selectedItemsLength - this.responses.length;
    },
  },
  created() {
    this.skillsArray = Object.keys(this.options.values).map((key) => [Number(key), this.options.values[key]]);
    this.skillsArray.sort((a, b) => {
      if (a[1].score < b[1].score) {
        return 1;
      }
      if (a[1].score > b[1].score) {
        return -1;
      }
      return 0;
    });
    this.skillList = this.skillsArray.slice(0, 9);
    this.skillsArray.forEach((match) => {
      this.matchedSkills.push(match[1].skill);
    });
  },
  methods: {
    seeMore() {
      this.skillList = this.skillsArray;
      this.showSeeMore = false;
    },
    disableInputs(key) {
      return (
        this.remainsSkillsToBeSelected <= 0
        && this.responses.indexOf(`${key}`) === -1 && (!this.responses.includes(key))
      );
    },
    response(e) {
      e.preventDefault();

      // create the response data that will be sent to the parent component with the key of the question and the array of responses
      const data = {
        key: this.questionKey,
        value: this.responses,
      };
      let messageContent = '';
      // Create the message to display in the chatbot
      this.responses.forEach((id) => {
        this.matchedSkills.forEach((matchedSkill) => {
          if (matchedSkill.id === parseInt(id, 10)) {
            messageContent += `${matchedSkill.name}\n`;
          }
        });
      });

      // Set the text message as object
      const message = {
        label: messageContent,
        user: true,
      };
      // Emit event to call 'pushMessage' function in parent component with the new message created
      this.$emit('pushMessage', message);
      // Emit Event to call 'disableInputs' function in parent component to disable the widget
      this.$emit('disableSkillsCheckList');
      // Emit event to call 'response' function in parent component and send data to it
      this.$emit('answered', data);
    },
    scrollToEnd() {
      const element = this.$refs.skillsCheckList;
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "_sass/_global.scss";
@import "_sass/vendors/_include-media.scss";
.content-holder {
background: $white;
  box-shadow: $box-shadow;
  border-radius: 10px;

}
.soft-skills-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  border-radius: 10px;

  @include media("<tablet") {
    grid-template-columns: repeat(2, 1fr);
  }
}
.skill-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.soft-skills-checkbox {
  height: 138px;
  border-bottom: 1px solid $gray-50;
  border-right: 1px solid $gray-50;
  position: relative;
  &:nth-child(3n) {
    border-right: unset;
  }
  @include media("<tablet") {
    &:nth-child(3n) {
      border-right: 1px solid $gray-50;
    }
  &:nth-child(2n) {
      border-right: unset;
    }
  }
}
.soft-skill-info {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 12.5px;
  width: 12.5px;
}
.soft-skills-checkbox label {
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.soft-skills-checkbox-input:checked ~ label {
  background: rgba(255, 223, 74, 0.3);
}
.img-holder img {
  height: 50px;
}
.title {
    font-family: $font-family-sans-serif;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;
text-align: center;
color: $gray-900;
margin-bottom: 10px;
}
.title-holder {
    font-family: $font-family-sans-serif;
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;

}
</style>
