<template>
	<div class="j-checkbox-input">
		<label
			class="align-items-start gap-1"
			:for="fieldName"
		>
			<input
				:id="fieldName"
				type="checkbox"
				class="form-checkbox"
				:name="fieldName"
				hidden
				:value="value"
				@input="(e) => $emit('input', !!e.target.value)"
			>
			<span class="check-box" />
			<span>
				{{ label }}
				<span
					v-if="required"
					class="required-mark"
				>
					*
				</span>
			</span>
		</label>
	</div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    fieldName: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

 <style lang="scss" scoped>
@import "_sass/_global.scss";

.j-checkbox-input {
  label {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1.2;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    color: $gray-900;
  }
  .check-box {
    border: 1px solid $gray-900;
    display: inline-block;
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
  }
  input:checked ~ .check-box {
    background-color: $primary;
  }
  .required-mark {
    color: $red;
    font-size: 16px;
    display: inline-block;
    font-weight: 700;
  }
}
</style>
