<template>
	<!-- Modal -->
	<div
		:id="chatbotId"
		class="modal fade pr-0"
		tabindex="-1"
		role="dialog"
		aria-labelledby="chat"
		aria-hidden="true"
	>
		<div
			class="modal-dialog modal-md"
			role="document"
		>
			<!-- Modal content -->
			<div class="modal-content">
				<chatbotCore
					:chatbot-id="chatbotId"
					:machine-name="machineName"
					:session-context="sessionContext"
					:session-messages="sessionMessages"
					:session-machine-name="sessionMachineName"
					:clean-session-bool="cleanSessionBool"
					:closable="closable"
					@showGeorges="showGeorges"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import chatbotCore from '_components/chatbot/chatbotCore.vue';
import jQuery from 'jquery';

(($) => {
  $(window).resize(() => {
    const modal = $('.modal'); // scrollTop works on <body> for some browsers, <html> for others
    if (modal) {
      if (document.getElementById('scroll-div')) {
        document.getElementById('scroll-div').scrollIntoView();
      }
    }
  });
})(jQuery);

export default {
  components: {
    chatbotCore,
  },
  props: {
    machineName: {
      type: String,
      default: '',
    },
    chatbotId: {
      type: String,
      default: '',
    },
    sessionContext: {
      type: String,
      default: '',
    },
    sessionMessages: {
      type: String,
      default: '',
    },
    sessionMachineName: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      cleanSessionBool: false,
      other: false,
      isMobile: false,
    };
  },

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  created() {
    //
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    showGeorges() {
      this.$emit('showGeorges');
    },

    cleanSession() {
      document.getElementById(this.chatbotId).classList.remove('show');
      document.getElementById('chatbot-modal').classList.remove('modal-background-opacity');
      if (this.$route.name === 'profilChatbot') {
        // reload page to check if the user completed his profile
        window.location.reload();
      }
      this.cleanSessionBool = true;
    },

    closeChatbot() {
      if (this.machineName === 'experience') {
        this.eventTrackCloseChatbotExperience();
      }
      this.cleanSession();
    },

    onResize() {
      const windowWidth = window.innerWidth;
      this.isMobile = windowWidth < 1024;
    },

    eventTrackCloseChatbotExperience() {
      window.dataLayer.push({
        event: 'clicCroixChatbotExperience',
      });
    },
  },
};
</script>
