import Vuex from 'vuex';
import jobreadynessStore from './jobreadyness.store';
import userStore from './user.store';
import externalEvaluationStore from './externalEvaluation.store';

const store = new Vuex.Store({
  modules: {
    jobreadynessStore,
    userStore,
    externalEvaluationStore,
  },
});

export default store;
