<template>
	<div class="panel-bottom bg-dark">
		<nav class="sub-nav-holder">
			<ul class="sub-nav list-unstyled">
				<li
					class="anchor introduction active"
				>
					<router-link
						:to="{ hash: 'introduction' }"
					>
						Présentation de la famille
					</router-link>
				</li>
				<li
					v-for="(skill) in currentFamilySkills.skills"
					:key="skill.id"
					class="anchor"
					:class="activeButton === stringToRoute(skill.name) ? 'active' : '' + stringToRoute(skill.name)"
				>
					<router-link
						:to="{ hash: '#' + stringToRoute(skill.name) }"
					>
						{{ skill.name }}
					</router-link>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>

export default {
  name: 'SubHeaderSoftSkillsFamily',

  props: {
    currentFamilySkills: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    activeButton: '',
  }),

  mounted() {
    window.addEventListener('scroll', this.detectPositionOfAnchor);
  },

  methods: {
    /**
		 * Detect position of section in order to add to anchor the active class
		 */
    detectPositionOfAnchor() {
      const sections = document.querySelectorAll('.section-mission, #introduction');
      const header = document.getElementById('header').offsetHeight;

      // For each section, we will detect if we can it. If not remove class
      sections.forEach((section) => {
        const topSect = section.offsetTop;
        const height = section.offsetHeight;

        const startOfSection = topSect - header;
        const endOfSection = topSect + height - window.innerHeight / 2;

        // Anchor corresponding to the section
        const element = document.getElementsByClassName(section.id);

        // If section can be seen in the window add class, otherwise remove it
        const sectionIsInWindow = window.pageYOffset > startOfSection && window.pageYOffset < endOfSection;
        if (sectionIsInWindow) {
          element[0].classList.add('active');
        } else {
          element[0].classList.remove('active');
        }
      });
    },

    stringToRoute(string) {
      return string.split(' ').join('-').toLowerCase().normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .replace("'", '-');
    },
  },
};
</script>
