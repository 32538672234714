<template>
	<!-- footer of the page -->
	<footer
		id="footer"
		class="footer footerNotConnected"
	>
		<div class="container">
			<div
				v-if="!$laravel.user"
				class="footer-block"
			>
				<ul class="footerMenu">
					<li>
						<a href="https://connect.article-1.eu/legals/conditions-generales" target="_blank" rel="noopener noreferrer">CGU</a>
					</li>
					<li>
						<a href="https://connect.article-1.eu/legals/politique-confidentialite" target="_blank" rel="noopener noreferrer">POLITIQUE DE CONFIDENTIALITÉ</a>
					</li>
					<li>
						<router-link
							:to="{ name: 'mentions_legales' }"
							exact
						>
							MENTIONS LEGALES
						</router-link>
					</li>
					<li>
						<router-link
							:to="{ name: 'contact' }"
							exact
						>
							CONTACT
						</router-link>
					</li>
					<li>
						<router-link
							:to="{ name: 'qui_sommes_nous' }"
							class="uppercase"
							exact
						>
							L'équipe
						</router-link>
					</li>
				</ul>
			</div>
			<div
				class="footer-block"
			>
				<div class="copyright">
					Copyright © Jobready 2019 - Site propulsé par <img src="/images/logo.svg" alt="Logo">
				</div>
			</div>
			<div
				class="footer-block"
			>
				<div class="copyright">
					NOTRE RÉFÉRENTIEL DE SOFT SKILLS <a
						class="yellow"
						target="_blank"
						href="/documents/soft-skills-r%C3%A9f%C3%A9rentiel.pdf"
						@click="eventTrackDisplayReferentiel"
					>
						<strong>ICI</strong>
					</a>
				</div>
			</div>
			<div
				class="footer-block"
			>
				<div class="copyright">
					ALLER PLUS LOIN EN CERTIFIANT UNE FAMILLE DE SOFT SKILLS <a
						class="yellow"
						target="_blank"
						href="https://article-1.eu/comprendre-la-certification-pratiquer-un-leadership-positif/"
						rel="noopener noreferrer"
					>
						<strong>LÀ</strong>.
					</a>
				</div>
			</div>
			<div
				class="footer-block"
			>
				<div class="develop">
					Programme développé par <img src="/images/logo-ar.svg" alt="Logo">
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
  methods: {
    eventTrackDisplayReferentiel() {
      window.dataLayer.push({
        event: 'clicCTAReferentielFooter',
      });
    },
  },
};
</script>
