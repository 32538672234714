<template>
	<div :class="{ 'd-none': !showGeorges }">
		<!-- CTA button -->
		<router-link
			id="georges-btn"
			:to="{
				name: 'chatbotByMachineName',
				params: {
					machineName: 'experience',
				},
			}"
			class="cta-btn georges-btn shadow-none"
			@click.native="georgesClicked"
		>
			<span class="sr-only">
				button description
			</span>
			<span class="btn-tooltip">
				Raconte-moi une expérience
			</span>
			<img
				class="shake"
				src="/images/mascotte.svg"
				alt="Chatbot Georges"
			>
		</router-link>
		<chatbot
			chatbot-id="chatbot-modal"
			machine-name="experience"
			session-context="context_chatbot"
			session-messages="chatbot_messages"
			session-machine-name="machine_name"
			:closable="closable"
			@showGeorges="dataReady"
		/>
	</div>
</template>

<script>
import Chatbot from '_components/chatbot/chatbot.vue';
import DataLayerPush from '_components/mixins/dataLayerPush';
import isMobile from '_components/mixins/isMobile';

export default {
  components: {
    chatbot: Chatbot,
  },

  mixins: [DataLayerPush, isMobile],
  props: {
    closable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      user: null,
      showGeorges: false,
    };
  },

  watch: {
    $route() {
      this.animateChatbot();
    },
  },

  created() {
    this.user = window.Laravel.user;
  },

  mounted() {
    this.animateChatbot();
  },

  methods: {
    animateChatbot() {
      if (this.user) {
        if ((!this.user.chatbot_button_clicked) || ((this.$route.name === 'mySteps' || this.$route.name === 'mySoftSkillsAndMyJourney') && !this.isMobileDevice)) {
          setTimeout(() => {
            $('#georges-btn').addClass('welcome-text');
            setTimeout(() => {
              $('#georges-btn').removeClass('welcome-text');
            }, 5000);
          }, 2000);
        }
      } else if (this.$route.name === 'index') {
        setTimeout(() => {
          $('#georges-btn').addClass('welcome-text');
          setTimeout(() => {
            $('#georges-btn').removeClass('welcome-text');
          }, 30000);
        }, 2000);
      }
    },

    dataReady() {
      this.showGeorges = true;
    },

    georgesClicked() {
      this.dataLayerPush('clicCTAToucan');
      if (this.user && !this.user.chatbot_button_clicked) {
        axios.post('/api/chatbot/georgesClicked').then(() => {
          this.user.chatbot_button_clicked = true;
        });
      }
    },
  },
};
</script>
