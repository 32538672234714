<template>
	<div class="auth-modal-body-wrapper">
		<div class="auth-modal-body-wrapper__container container">
			<h1 class="j_heading-1 j_font-color-base mb-4">
				{{ title }}
			</h1>
			<div class="auth-modal-body-wrapper__container-body">
				<slot name="content" />
			</div>
		</div>

		<div
			class="george__img"
			:class="{ 'show-on-mobile': showGeorgeImageOnMobile }"
		>
			<img
				:src="georgeImage"
				alt="Image de georges content"
			>
		</div>
	</div>
</template>

<script>
export default {

  props: {
    georgeImage: {
      required: true,
      type: String,
    },
    showGeorgeImageOnMobile: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      required: true,
      type: String,
    },
  },

};
</script>
<style lang="scss" scoped>
@import '_sass/_global.scss';
@import '_sass/vendors/_include-media.scss';

#register-modal___BV_modal_outer_ {
	z-index: 100 !important;
}

.container {
  padding: 45px 30px;
}

.auth-modal-body-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  align-content: center;

	.george__img {
		display: none;
        &.show-on-mobile {
            display: unset;
            position: absolute;
            bottom: 0;
        }
	}

	@include media('>=desktop') {
		display: grid;
		grid-template-columns: 20% 65% 15%;
		gap: 3rem;
		height: 100%;
		.george__img {
			display: unset;
			grid-area: 1;
			align-self: center;
            &.show-on-mobile {
                display: unset;
                position: unset;
                bottom: unset;
            }

			img {
				width: 100%;
			}
		}
		.auth-modal-body-wrapper__container {
			display: flex;
			flex-direction: column;

			.auth-modal-body-wrapper__container-body {
				display: grid;
				gap: 60px;
				align-items: center;
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}
</style>
