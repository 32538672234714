<template>
	<div
		ref="multiCheck"
		class="message-block user-message"
	>
		<!-- chat cta area -->
		<div class="chat-cta-block">
			<div
				class="d-flex justify-content-center align-items-center flex-wrap gap-2"
			>
				<label
					v-for="(value, key) in options.values"
					:key="key"
					:for="key"
					class="
            multi-check-button-item
            d-flex
            justify-content-center
            align-items-center
          "
					:class="{ 'multi-check-button-item--disabled': noneBtn }"
					@click.stop="scrollToEnd"
				>
					<input
						:id="key"
						v-model="tempResponse"
						type="checkbox"
						:value="value"
						hidden
						:disabled="noneBtn"
					>
					<span class="check-input" />
					{{ value }}
				</label>
				<label
					v-if="options.none"
					:for="none"
					class="
            multi-check-button-item
            d-flex
            justify-content-center
            align-items-center
          "
					:class="{ 'multi-check-button-item--disabled': tempResponse.length > 0 }"
				>
					<input
						id="none"
						v-model="noneBtn"
						type="checkbox"
						:value="options.none"
						hidden
						:disabled="tempResponse.length > 0"
						@change="selectNoneBtn"
					>
					<span class="check-input" />
					{{ options.none }}
				</label>
			</div>
			<div class="text-center">
				<button
					class="btn btn-primary btn-chatbot"
					:disabled="!isValid"
					type="button"
					@click="validateResponse"
				>
					Valider
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    questionKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      noneBtn: null,
      tempResponse: [],
    };
  },
  computed: {
    isValid() {
      return this.tempResponse.length > 0 || this.noneBtn;
    },
  },
  methods: {
    selectNoneBtn() {
      this.tempResponse = [];
    },
    validateResponse() {
      const message = {
        label: this.noneBtn ? this.options.none : this.tempResponse.join(' / '),
        user: true,
        key: this.questionKey,
      };
      const data = {
        key: this.questionKey,
        value: this.noneBtn ? this.options.none : this.tempResponse,
      };
      // Emit event to call 'pushMessage' function in parent component with the new message created
      this.$emit('pushMessage', message);
      // Emit Event to call 'disableInputs' function in parent component to disable the widget
      this.$emit('disableMultiCheck');
      // Emit event to call 'response' function in parent component and send data to it
      this.$emit('answered', data);
    },
    scrollToEnd() {
      const element = this.$refs.multiCheck;
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
  },
};
</script>

<style scoped lang="scss">
@import "_sass/_global.scss";
.user-message {
  margin-left: unset !important;
  width: 100%;
}
.multi-check-button-item {
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(178, 178, 178, 0.18);
  border-radius: 100px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  gap: 10px;
  color: #353535;
  cursor: pointer;
  &.multi-check-button-item--disabled {
    cursor: unset;
  }
}
.check-input {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #f7f7f7;
  border: 0.5px solid #b2b2b2;
  box-shadow: inset 2px 2px 3px rgba(178, 178, 178, 0.18);
  border-radius: 100%;
}
input:checked + .check-input {
  background: $primary;
  box-shadow: unset;
}
input:disabled + .check-input {
  background: #d8d8d8;
}
</style>
