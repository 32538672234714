<template>
	<div class="tags-list-wrapper date-wrapper">
		<form
			class="date-widget-form"
			action="#"
		>
			<select
				v-model="responseYear"
				class="custom-select date-widget-select"
				@change="activateValidationBtn"
			>
				<option hidden>
					Année
				</option>
				<option
					v-for="(year, yearKey) in years"
					:key="yearKey"
					:value="year"
				>
					{{ year }}
				</option>
			</select>
			<div class="bottom-block mb-2">
				<button
					class="btn btn-primary rounded-pill btn-date-widget btn-chatbot"
					type="submit"
					:disabled="!validate"
					@click="response"
				>
					Valider
				</button>
			</div>
		</form>
	</div>
</template>

<script>

export default {
  props: {
    questionKey: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      responseYear: 'Année',
      validate: false,
    };
  },
  computed: {
    // Get years as array from 1920 to now or from startDate if it exists
    years() {
      const year = new Date().getFullYear();
      return Array.from({ length: year - 1920 + 1 }, (value, index) => 1920 + index).reverse();
    },
  },
  methods: {
    response(e) {
      e.preventDefault();
      // Get the key of the current question and the element selected By the user
      const data = {
        key: this.questionKey,
        value: this.responseYear,
      };
      // Set the text message as object
      const message = {
        label: this.responseYear,
        user: true,
        key: this.questionKey,
      };
      // Emit event to call 'pushMessage' function in parent component with the new message created
      this.$emit('pushMessage', message);

      // Emit Event to call 'disableInputs' function in parent component to disable the widget
      this.$emit('disableSelectDate');

      // Emit event to call 'response' function in parent component and send data to it
      this.$emit('answered', data);
    },
    activateValidationBtn() {
      this.validate = this.responseYear !== 'Année';
    },
  },
};
</script>
