<template>
	<b-modal
		id="subscribe-to-newsletter"
		header-class="modal-header bg-secondary text-white"
		body-class="login-modal"
		:hide-footer="true"
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		content-class="registration-form creation-form"
		modal-class="modal-background-opacity modal fade"
	>
		<template #modal-header>
			<h5 class="modal-title">
				S'abonner à notre newsletter
			</h5>
			<a
				href="#"
				class="d-flex align-items-center justify-content-center close right-close"
				@click="() => $bvModal.hide('subscribe-to-newsletter')"
			>
				<font-awesome-icon :icon="['far', 'times-circle']" /><span class="sr-only">
					close
				</span>
			</a>
		</template>
		<template #default>
			<form @submit.prevent="subscribe">
				<div class="row">
					<div class="col-md-12">
						<div
							v-if="error"
							class="alert alert-danger"
						>
							{{ error }}
						</div>
						<div class="form-group">
							<label for="dailyCode">
								Entrer votre email
							</label>
							<input
								id="dailyCode"
								v-model="email"
								type="email"
								class="form-control"
								required
							>
						</div>
						<div class="form-group mt-3">
							<button
								type="submit"
								class="btn btn-primary btn-block"
							>
								Valider
							</button>
						</div>
					</div>
				</div>
			</form>
		</template>
	</b-modal>
</template>
<script>
import newsletterSubscribe from '../../graphql/newsletterSubscribe.gql';

export default {
  data() {
    return {
      email: '',
      error: null,
    };
  },

  methods: {
    async subscribe() {
      try {
        const response = await this.$apollo.mutate({
          mutation: newsletterSubscribe,
          variables: {
            email: this.email,
          },
        });
        if (response.data.subscribe) {
          this.$bvModal.hide('subscribe-to-newsletter');
        } else this.error = 'L\'email que vous avez founie n\'est pas valide';
      } catch (error) {
        this.error = 'L\'email que vous avez founie n\'est pas valide';
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
	#subscribe-to-newsletter___BV_modal_outer_ { z-index: 100 !important;}
	.right-close {
		color: white;
		opacity: 1;
	}
</style>
