<template>
	<div
		ref="multiCheck"
		class="message-block user-message"
	>
		<!-- chat cta area -->
		<div class="chat-cta-block">
			<form
				action="#"
				@submit.prevent=""
			>
				<div class="content-holder checkbox-block">
					<!-- custom checkbox -->
					<div
						v-for="(value, key) in options.values"
						:key="value.id"
						class="custom-checkbox custom-control"
						:class="{ 'selected-multi-check': noneBtn }"
						@click.stop="scrollToSearchInput"
					>
						<input
							:id="key"
							v-model="responses"
							type="checkbox"
							class="custom-control-input multiCheckItem"
							:value="value"
							:disabled="noneBtn"
						>
						<label
							class="custom-control-label"
							:for="key"
						>
							<!-- skill block sm -->
							<span
								class="skill-block sm"
								:class="{ 'selected-multi-check': noneBtn }"
							>
								<span class="inner-block">
									<strong class="title">
										{{ value }}
									</strong>
								</span>
							</span>
						</label>
					</div>
					<!-- other checkbox -->
					<div
						v-if="options.other"
						class="custom-checkbox custom-control"
						:class="{ 'selected-multi-check': noneBtn }"
					>
						<input
							id="-1"
							v-model="otherResponse"
							type="checkbox"
							class="custom-control-input multiCheckItem"
							value="-1"
							:disabled="noneBtn"
						>
						<label
							class="custom-control-label"
							for="-1"
						>
							<!-- skill block sm -->
							<span
								class="skill-block sm"
								:class="{ 'selected-multi-check': noneBtn }"
							>
								<span class="inner-block">
									<strong class="title">
										{{ options.other }}
									</strong>
								</span>
							</span>
						</label>
					</div>
					<!-- none checkbox -->
					<div
						v-if="options.none"
						class="custom-checkbox custom-control"
						:class="{ 'selected-multi-check': !emptyList }"
					>
						<input
							id="none"
							type="checkbox"
							class="custom-control-input multiCheckItem"
							value="0"
							:disabled="!emptyList"
							@click="noneBtnToggle"
						>
						<label
							class="custom-control-label"
							for="none"
						>
							<!-- skill block sm -->
							<span
								class="skill-block sm"
								:class="{ 'selected-multi-check': !emptyList }"
							>
								<span class="inner-block">
									<strong class="title">
										{{ options.none }}
									</strong>
								</span>
							</span>
						</label>
					</div>
					<div class="show-more">
						<a
							v-if="showSeeMore && questionKey === 'tasks'"
							href="#"
							@click="seeMoreTasks"
						>
							<b>en voir plus</b>
						</a>
					</div>
				</div>
				<div
					v-if="options.showSearchBar"
					class="input-search mt-2"
				>
					<input
						id="profession-search-bar"
						type="text"
						name="search-bar"
						class="form-control btn-search-chatbot"
						placeholder="Recherche..."
						@click.stop="scrollToSearchInput"
						@keyup="filter($event.target.value)"
					>
				</div>
			</form>
		</div>

		<div class="text-center mt-4">
			<button
				class="btn btn-primary btn-chatbot"
				type="button"
				:disabled="!validate"
				@click="response"
			>
				Valider
			</button>
		</div>
	</div>
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    questionKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      emptyList: true,
      noneBtn: false,
      showSeeMore: true,
      responses: [],
      otherResponse: null,
    };
  },
  computed: {
    validate() {
      if (this.noneBtn) {
        return true;
      }

      const isLessThenZeroAndMax = this.options.max && this.responses.length > 0 && this.responses.length <= this.options.max;

      if (isLessThenZeroAndMax) {
        return true;
      }

      if (this.options.max === null && this.responses.length > 0) {
        return true;
      }

      return false;
    },
  },
  methods: {
    response(e) {
      e.preventDefault();

      // create the response data that will be sent to the parent component with the key of the question and the array of responses
      const data = {
        key: this.questionKey,
        value: this.responses,
      };
      let messageContent = '';
      // Create the message to display in the chatbot
      this.responses.forEach((response) => {
        messageContent += `${response}\n`;
      });

      // if none selected push options.noneLabel in chatbot
      if (this.responses.length === 0 && this.options.none) {
        messageContent = this.options.none;
      }

      // Set the text message as object
      const message = {
        label: messageContent,
        user: true,
        key: this.questionKey,
      };
      // If other case is checked push the label of the other question in chatbot
      if (this.otherResponse) {
        // Emit event to call 'pushMessage' function in parent component with the new message created
        if (message.label) {
          this.$emit('pushMessage', message);
        }
        this.$emit('pushMultiCheckOtherResponse', data);
        return;
      }
      // Emit event to call 'pushMessage' function in parent component with the new message created
      this.$emit('pushMessage', message);
      // Emit Event to call 'disableInputs' function in parent component to disable the widget
      this.$emit('disableMultiCheck');
      // Emit event to call 'response' function in parent component and send data to it
      this.$emit('answered', data);
    },
    // on click on none input change noneBtn value
    noneBtnToggle() {
      this.noneBtn = !this.noneBtn;
    },
    seeMoreTasks() {
      axios
        .post('/api/chatbot/all-tasks')
        .then((response) => {
          this.options.values = response.data.values;
          this.showSeeMore = false;
        })
        .catch(() => {
          const message = {
            label: 'Une erreur est survenue, rafraîchis la page !',
          };
          this.messages.push(message);
        });
    },
    filter(searchContent) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      const filterEndpoints = {
        profession: '/api/chatbot/filter-profession',
        rolesVolunteers: '/api/chatbot/filter-roles-volunteers',
        sport: '/api/chatbot/filter-sports',
        hobby: '/api/chatbot/filter-sports',
        tasks: '/api/chatbot/filter-tasks',
      };
      this.timer = setTimeout(() => {
        if (searchContent.length >= 1) {
          const data = {
            searchContent,
          };
          axios
            .post(filterEndpoints[this.questionKey], data)
            .then((response) => {
              this.options.values = null;
              const vals = response.data.values.values;
              this.$nextTick(() => {
                this.options.values = vals;
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, 800);
    },

    scrollToSearchInput() {
      const element = this.$refs.multiCheck;
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
  },
};
</script>

<style scoped lang="scss">
@import "_sass/_global.scss";

.user-message {
	margin-left: unset !important;
	width: 100%;
}

.content-holder {
  background: $white;
  box-shadow: $box-shadow;
  border-radius: 10px;
  padding-left: 20px;
}

.custom-checkbox .custom-control-label::before {
	border-radius: 1rem;
}

.custom-control-label {
  height: 56px;
  display: flex;
  align-items: center;
}
</style>
