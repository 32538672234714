<template>
	<b-modal
		id="new-pwd-modal"
		header-class="modal-header text-dark"
		:hide-footer="true"
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		modal-class="modal fade full-screen-modal"
	>
		<template #modal-header>
			<router-link
				type="button"
				class="modal-btn-close"
				:to="{ name: 'index' }"
				exact
			/>
		</template>
		<template #default>
			<div class="new-pwd-content">
				<div class="password-reset-form__container container">
					<div>
						<h1 class="j_heading-1 j_font-color-base">
							SAISIS TON NOUVEAU
							MOT DE PASSE
						</h1>
						<div>
							<j-form-input
								v-model="password"
								class="resetpwd-input mt-4 mt-md-5 mb-4 mb-md-3"
								field-name="password"
								label="Nouveau mot de passe"
								field-type="password"
								:errors="errors"
							/>
							<div class="text-center j_font-color-base">
								<button
									:disabled="loading || !passwordIsValid"
									class="btn btn-primary font-weight-bold"
									type="button"
									@click="submitForm"
								>
									Valider
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="george__img">
					<img
						src="/images/george-2.svg"
						alt="Image de Georges"
					>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import jFormInput from '_components/layout/jFormInput.vue';

export default {
  components: { jFormInput },
  data() {
    return {
      password: null,
      loading: false,
      errors: {
        password: null,
      },
    };
  },

  computed: {
    passwordIsValid() {
      return this.password !== null && this.password !== '';
    },
  },

  watch: {
    $route: {
      handler() {
        this.modalShowing();
      },
    },
  },

  mounted() {
    this.modalShowing();
  },

  methods: {
    submitForm(e) {
      e.preventDefault();

      this.loading = true;

      // Resetting errors when submitting
      this.errors = {
        password: null,
      };

      axios
        .post('/api/updatePwd', {
          password: this.password,
          password_confirmation: this.password,
        })
        .then((response) => {
          if (response.data.isPwdUpdated) {
            this.$router.push({ name: 'login' });
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.errors) {
            this.password = '';
            this.errors = error.response.data.errors;
          } else {
            console.log(error);
          }
        });
    },

    modalShowing() {
      if (this.$route.name === 'accueilNewpwd') {
        this.$bvModal.show('new-pwd-modal');
      } else {
        this.$bvModal.hide('new-pwd-modal');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "_sass/_global.scss";

.success__message {
	color: $green;
}

.new-pwd-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	.george__img {
		display: flex;
		opacity: 0.6;
		justify-content: center;
	}

	@media (min-width: 1000px) {
		display: grid;
		grid-template-columns: 20% 80%;
		align-items: center;
		height: 100%;
		.george__img {
			display: unset;
			grid-area: 1;
			align-self: center;

			img {
				width: 100%;
			}
		}
		.password-reset-form__container {
			display: grid;
			grid-template-columns: 60% 40%;
			align-items: center;
			height: 100%;
			margin-left: 9rem;

			form {
				width: 75%;
			}
		}
	}
}
</style>
