/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = {
  showModal: false,
  modalContent: null,
};

const mutations = {
  SHOW_MODAL(state, payload) {
    state.showModal = payload.showModal;
    state.modalContent = payload.modalContent;
  },
  HIDE_MODAL(state, payload) {
    state.showModal = payload;
  },
};

const actions = {
  showModal({ commit }, payload) {
    commit('SHOW_MODAL', {
      modalContent: payload,
      showModal: true,
    });
  },
  hideModal({ commit }) {
    commit('HIDE_MODAL', false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
